export const environment = {
  production: false,
  sentry: 'https://df067877dc0a6103b560ad469e9b6176@o446789.ingest.us.sentry.io/4507498338516992',
  name: 'Staging',
  apiUrl: 'https://staging.crownbasenxt.be/api',
  auth0: {
    domain: 'staging-royalcrown.eu.auth0.com',
    clientId: 'RFTI8h2J50vDSLtSIxwYhpWXFThFxgGq',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://staging.api.royalcrown.be/',
      logoutUrl: window.location.origin,
    },
    useRefreshTokens: true,
    httpInterceptor: {
      allowedList: [
        {
          // Match any request that starts 'https://{yourDomain}/api/v2/' (note the asterisk)
          uri: `https://staging.crownbasenxt.be/api/*`,
          tokenOptions: {
            authorizationParams: {
              // The attached token should target this audience
              audience: 'https://staging.api.royalcrown.be/',
              scope: 'openid profile email offline_access',
            },
          },
        },
      ],
    },
  },
};
