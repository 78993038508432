// Angular Modules
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Entity } from '../models/entities/Entity';
import { Constants } from '../config/Constants';

interface Options {
  body?: never;
  headers?: Record<string, string | string[]>;
  context?: HttpContext;
  observe?: 'body';
  params?: HttpParams | Record<string, string | number | boolean | readonly (string | number | boolean)[]>;
  reportProgress?: boolean;
  responseType: 'arraybuffer';
  withCredentials?: boolean;
  transferCache?:
    | {
        includeHeaders?: string[];
      }
    | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiHttpService<T extends Entity> {
  options: Options = {} as Options;
  headers: Record<string, any> = {
    'Content-Type': 'application/ld+json; charset=utf-8',
  };
  isLoading = true;
  urlParam = new URLSearchParams();

  constructor(private http: HttpClient) {
    this.options.headers = this.headers;
    this.options.observe = 'response' as 'body';
  }

  public get(url: string) {
    url = Constants.API_ENDPOINT + url;
    return this.http.get(url, this.options);
  }

  public getById(url: string) {
    url = Constants.API_ENDPOINT + url;
    return this.http.get(url, this.options);
  }

  public getAll(url: string) {
    url = Constants.API_ENDPOINT + url + '?pagination=false';
    return this.http.get(url, this.options);
  }

  public post(url: string, data: T) {
    url = Constants.API_ENDPOINT + url;
    return this.http.post(url, data, this.options);
  }

  public postMedia(url: string, data: any) {
    url = Constants.API_ENDPOINT + url;
    if (this.options && this.options.headers) {
      this.options.headers['Content-Type'] = 'multipart/form-data';
    }

    return this.http.post(url, data, this.options);
  }

  public put(url: string, data: T) {
    url = Constants.API_ENDPOINT + url;
    return this.http.put(url, data, this.options);
  }

  public delete(url: string) {
    url = Constants.API_ENDPOINT + url;
    return this.http.delete(url, this.options);
  }

  // public setHeaders(headers: HttpHeaders): void {
  //   this.headers = headers;
  // }

  public showImage(url: string) {
    url = Constants.API_ENDPOINT + url;
    if (this.options && this.options.headers) {
      this.options.headers['Content-Disposition'] = 'attachment';
    }
    return this.http.get(url, this.options);
  }

  hasToken(): boolean {
    return !!this.options && !!this.options.headers && !!this.options.headers['Authorization'];
  }
}
