import { Pipe, PipeTransform } from '@angular/core';
import { NumberPipe } from './number.pipe';

@Pipe({
  name: 'capitalize',
  standalone: true,
})
export class CapitalizePipe implements PipeTransform {
  private numberPipe: NumberPipe = new NumberPipe();

  transformNumber(value: string | number) {
    return this.numberPipe.transform(value);
  }

  transform(
    value: string,
    capitalizationMethod?: 'allUpperCase' | 'titleCase' | 'sentenceCase' | 'email' | 'number' | 'rates',
  ): string {
    if (capitalizationMethod === 'allUpperCase') {
      return value.toUpperCase();
    } else if (capitalizationMethod === 'titleCase') {
      const splitString = value.split(' ').map((s) => `${s[0].toUpperCase()}${s.slice(1)}`);
      return splitString.join(' ');
    } else if (capitalizationMethod === 'email') {
      return value;
    } else if (capitalizationMethod === 'number') {
      return this.transformNumber(value);
    } else if (capitalizationMethod === 'rates') {
      const arr = value.split('/').map((val) => val.replace(' ', ''));
      const sub = arr.map((val) => this.transformNumber(val));
      return sub.join('/');
    } else if (!capitalizationMethod || capitalizationMethod === 'sentenceCase') {
      const splitString =
        value.indexOf('@') < 0
          ? value.split('.').map((s) => {
              const trimmedString = s.trim();
              if (trimmedString.length > 0) {
                return `${trimmedString[0].toUpperCase()}${trimmedString.slice(1)}`;
              }
              return '';
            })
          : [`${value[0].toUpperCase()}${value.slice(1)}`];
      return splitString.join('. ');
    }
    return '';
  }
}
