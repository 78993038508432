/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Component, HostBinding, Input, Directive, Optional, EventEmitter, Output, ViewChild, ContentChild, ViewChildren, isDevMode, ElementRef, NgModule } from '@angular/core';
import * as i1 from '@progress/kendo-angular-l10n';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { validatePackage } from '@progress/kendo-licensing';
import { focusableSelector, closestInScope as closestInScope$1, isPresent as isPresent$1, isDocumentAvailable, Keys, ResizeSensorComponent, ResizeBatchService } from '@progress/kendo-angular-common';
import { NgIf, NgTemplateOutlet, NgFor, NgClass, NgStyle, AsyncPipe } from '@angular/common';
import { Subscription, fromEvent, merge, ReplaySubject, Subject } from 'rxjs';
import * as i2 from '@angular/animations';
import { style, animate } from '@angular/animations';
import { take, filter, map, startWith, share } from 'rxjs/operators';
import { IconWrapperComponent, IconsService } from '@progress/kendo-angular-icons';
import { chevronRightIcon, chevronLeftIcon } from '@progress/kendo-svg-icons';

/**
 * @hidden
 */
const _c0 = ["*"];
const _c1 = ["kendoActionSheetItem", ""];
const _c2 = a0 => ({
  $implicit: a0
});
function ActionSheetItemComponent_0_ng_template_0_Template(rf, ctx) {}
function ActionSheetItemComponent_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ActionSheetItemComponent_0_ng_template_0_Template, 0, 0, "ng-template", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c2, ctx_r0.item));
  }
}
function ActionSheetItemComponent_ng_template_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵelement(1, "kendo-icon-wrapper", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵstyleMap(ctx_r0.manageIconStyles(ctx_r0.item));
    i0.ɵɵclassMap(ctx_r0.manageIconClasses(ctx_r0.item));
    i0.ɵɵproperty("name", ctx_r0.item.icon)("customFontClass", ctx_r0.item.iconClass)("svgIcon", ctx_r0.item.svgIcon);
  }
}
function ActionSheetItemComponent_ng_template_1_span_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 11);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.item.title);
  }
}
function ActionSheetItemComponent_ng_template_1_span_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.item.description);
  }
}
function ActionSheetItemComponent_ng_template_1_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 8);
    i0.ɵɵtemplate(1, ActionSheetItemComponent_ng_template_1_span_2_span_1_Template, 2, 1, "span", 9)(2, ActionSheetItemComponent_ng_template_1_span_2_span_2_Template, 2, 1, "span", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.item.title);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.item.description);
  }
}
function ActionSheetItemComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵtemplate(1, ActionSheetItemComponent_ng_template_1_span_1_Template, 2, 7, "span", 4)(2, ActionSheetItemComponent_ng_template_1_span_2_Template, 3, 2, "span", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.item.icon || ctx_r0.item.iconClass || ctx_r0.item.svgIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.item.title || ctx_r0.item.description);
  }
}
const _c3 = ["kendoActionSheetList", ""];
function ActionSheetListComponent_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 1);
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("k-actionsheet-item", true)("k-disabled", item_r1.disabled);
    i0.ɵɵproperty("ngClass", item_r1.cssClass)("ngStyle", item_r1.cssStyle)("itemTemplate", ctx_r1.itemTemplate)("item", item_r1);
    i0.ɵɵattribute("aria-disabled", item_r1.disabled)("kendo-actionsheet-item-index", ctx_r1.setAttrIndex(item_r1));
  }
}
const _c4 = ["childContainer"];
function ActionSheetComponent_ng_container_0_6_ng_template_0_Template(rf, ctx) {}
function ActionSheetComponent_ng_container_0_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ActionSheetComponent_ng_container_0_6_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.actionSheetTemplate == null ? null : ctx_r1.actionSheetTemplate.templateRef);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_0_1_ng_template_0_Template(rf, ctx) {}
function ActionSheetComponent_ng_container_0_ng_template_7_div_0_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ActionSheetComponent_ng_container_0_ng_template_7_div_0_1_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.headerTemplate == null ? null : ctx_r1.headerTemplate.templateRef);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_0_ng_template_2_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 19);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.title);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_0_ng_template_2_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 20);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.subtitle);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15)(1, "div", 16);
    i0.ɵɵtemplate(2, ActionSheetComponent_ng_container_0_ng_template_7_div_0_ng_template_2_div_2_Template, 2, 1, "div", 17)(3, ActionSheetComponent_ng_container_0_ng_template_7_div_0_ng_template_2_div_3_Template, 2, 1, "div", 18);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵproperty("id", ctx_r1.titleId);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.title);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.subtitle);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 14);
    i0.ɵɵtemplate(1, ActionSheetComponent_ng_container_0_ng_template_7_div_0_1_Template, 1, 1, null, 9)(2, ActionSheetComponent_ng_container_0_ng_template_7_div_0_ng_template_2_Template, 4, 3, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const defaultHeaderTemplate_r3 = i0.ɵɵreference(3);
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.headerTemplate)("ngIfElse", defaultHeaderTemplate_r3);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_1_1_ng_template_0_Template(rf, ctx) {}
function ActionSheetComponent_ng_container_0_ng_template_7_div_1_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ActionSheetComponent_ng_container_0_ng_template_7_div_1_1_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.contentTemplate == null ? null : ctx_r1.contentTemplate.templateRef);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_1_ng_template_2_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 24);
    i0.ɵɵlistener("itemClick", function ActionSheetComponent_ng_container_0_ng_template_7_div_1_ng_template_2_div_0_Template_div_itemClick_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(5);
      return i0.ɵɵresetView(ctx_r1.onItemClick($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("groupItems", ctx_r1.topGroupItems)("allItems", ctx_r1.items)("itemTemplate", ctx_r1.itemTemplate == null ? null : ctx_r1.itemTemplate.templateRef);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_1_ng_template_2_hr_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "hr", 25);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_1_ng_template_2_div_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 24);
    i0.ɵɵlistener("itemClick", function ActionSheetComponent_ng_container_0_ng_template_7_div_1_ng_template_2_div_2_Template_div_itemClick_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(5);
      return i0.ɵɵresetView(ctx_r1.onItemClick($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("groupItems", ctx_r1.bottomGroupItems)("allItems", ctx_r1.items)("itemTemplate", ctx_r1.itemTemplate == null ? null : ctx_r1.itemTemplate.templateRef);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ActionSheetComponent_ng_container_0_ng_template_7_div_1_ng_template_2_div_0_Template, 1, 3, "div", 22)(1, ActionSheetComponent_ng_container_0_ng_template_7_div_1_ng_template_2_hr_1_Template, 1, 0, "hr", 23)(2, ActionSheetComponent_ng_container_0_ng_template_7_div_1_ng_template_2_div_2_Template, 1, 3, "div", 22);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngIf", ctx_r1.topGroupItems);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.shouldRenderSeparator);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.bottomGroupItems);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 21);
    i0.ɵɵtemplate(1, ActionSheetComponent_ng_container_0_ng_template_7_div_1_1_Template, 1, 1, null, 9)(2, ActionSheetComponent_ng_container_0_ng_template_7_div_1_ng_template_2_Template, 3, 3, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const defaultContentTemplate_r6 = i0.ɵɵreference(3);
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.contentTemplate)("ngIfElse", defaultContentTemplate_r6);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_div_2_ng_template_1_Template(rf, ctx) {}
function ActionSheetComponent_ng_container_0_ng_template_7_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 26);
    i0.ɵɵtemplate(1, ActionSheetComponent_ng_container_0_ng_template_7_div_2_ng_template_1_Template, 0, 0, "ng-template", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.footerTemplate == null ? null : ctx_r1.footerTemplate.templateRef);
  }
}
function ActionSheetComponent_ng_container_0_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ActionSheetComponent_ng_container_0_ng_template_7_div_0_Template, 4, 2, "div", 11)(1, ActionSheetComponent_ng_container_0_ng_template_7_div_1_Template, 4, 2, "div", 12)(2, ActionSheetComponent_ng_container_0_ng_template_7_div_2_Template, 2, 1, "div", 13);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r1.title || ctx_r1.subtitle || ctx_r1.headerTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.items || ctx_r1.contentTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.footerTemplate);
  }
}
function ActionSheetComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵlistener("click", function ActionSheetComponent_ng_container_0_Template_div_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onOverlayClick());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "div", 6)(3, "div", 7, 0)(5, "div", 8);
    i0.ɵɵtemplate(6, ActionSheetComponent_ng_container_0_6_Template, 1, 1, null, 9)(7, ActionSheetComponent_ng_container_0_ng_template_7_Template, 3, 3, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const defaultTemplate_r7 = i0.ɵɵreference(8);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵstyleMap("bottom: 0px; width: 100%;");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngClass", ctx_r1.cssClass);
    i0.ɵɵattribute("aria-labelledby", ctx_r1.titleId);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.actionSheetTemplate)("ngIfElse", defaultTemplate_r7);
  }
}
const _c5 = ["kendoBottomNavigationItem", ""];
function BottomNavigationItemComponent_ng_container_0_kendo_icon_wrapper_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-icon-wrapper", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("name", ctx_r0.item.icon)("customFontClass", ctx_r0.item.iconClass)("svgIcon", ctx_r0.item.svgIcon);
  }
}
function BottomNavigationItemComponent_ng_container_0_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.item.text);
  }
}
function BottomNavigationItemComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, BottomNavigationItemComponent_ng_container_0_kendo_icon_wrapper_1_Template, 1, 3, "kendo-icon-wrapper", 1)(2, BottomNavigationItemComponent_ng_container_0_span_2_Template, 2, 1, "span", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.itemIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.item.text);
  }
}
function BottomNavigationItemComponent_1_ng_template_0_Template(rf, ctx) {}
function BottomNavigationItemComponent_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, BottomNavigationItemComponent_1_ng_template_0_Template, 0, 0, "ng-template", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.itemTemplate == null ? null : ctx_r0.itemTemplate.templateRef)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c2, ctx_r0.item));
  }
}
function BottomNavigationComponent_ng_container_0_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 2);
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const idx_r2 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("disabledComponent", ctx_r2.disabled)("item", item_r1)("index", idx_r2)("selectedIdx", ctx_r2.selectedIdx)("itemTemplate", ctx_r2.itemTemplate)("ngClass", item_r1.cssClass)("ngStyle", item_r1.cssStyle)("orientation", ctx_r2.itemFlow);
    i0.ɵɵattribute("data-kendo-bottomnavigation-index", idx_r2);
  }
}
function BottomNavigationComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, BottomNavigationComponent_ng_container_0_span_1_Template, 1, 9, "span", 1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.items);
  }
}
const _c6 = ["kendoBreadCrumbItem", ""];
const _c7 = [[["", "kendoBreadCrumbSeparator", ""]]];
const _c8 = ["[kendoBreadCrumbSeparator]"];
const _c9 = (a0, a1, a2, a3, a4) => ({
  "k-breadcrumb-root-link": a0,
  "k-breadcrumb-link": a1,
  "k-breadcrumb-icontext-link": a2,
  "k-breadcrumb-icon-link": a3,
  "k-disabled": a4
});
const _c10 = (a0, a1) => ({
  $implicit: a0,
  index: a1
});
function BreadCrumbItemComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
function BreadCrumbItemComponent_ng_container_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function BreadCrumbItemComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, BreadCrumbItemComponent_ng_container_2_ng_container_1_Template, 1, 0, "ng-container", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const separator_r1 = i0.ɵɵreference(1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", separator_r1);
  }
}
function BreadCrumbItemComponent_ng_container_3_span_1_img_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "img", 8);
    i0.ɵɵlistener("load", function BreadCrumbItemComponent_ng_container_3_span_1_img_1_Template_img_load_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r2.onImageLoad());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("src", ctx_r2.item.data.imageUrl, i0.ɵɵsanitizeUrl);
  }
}
function BreadCrumbItemComponent_ng_container_3_span_1_kendo_icon_wrapper_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-icon-wrapper", 9);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("name", ctx_r2.item.data.icon)("customFontClass", ctx_r2.item.data.iconClass)("svgIcon", ctx_r2.item.data.svgIcon);
  }
}
function BreadCrumbItemComponent_ng_container_3_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵtemplate(1, BreadCrumbItemComponent_ng_container_3_span_1_img_1_Template, 1, 1, "img", 5)(2, BreadCrumbItemComponent_ng_container_3_span_1_kendo_icon_wrapper_2_Template, 1, 3, "kendo-icon-wrapper", 6);
    i0.ɵɵelementStart(3, "span", 7);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction5(8, _c9, ctx_r2.item.context.isFirst, ctx_r2.index !== 0, !!ctx_r2.item.data.icon && !!ctx_r2.item.data.text, !!ctx_r2.item.data.icon && !ctx_r2.item.data.text, ctx_r2.disabled))("title", ctx_r2.item.data.title || "")("tabindex", ctx_r2.disabled ? -1 : 0);
    i0.ɵɵattribute("aria-disabled", ctx_r2.disabled)("aria-current", ctx_r2.item.context.isLast ? "page" : null);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.item.data.imageUrl);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.item.data.icon || ctx_r2.item.data.iconClass || ctx_r2.item.data.svgIcon);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r2.item.data.text);
  }
}
function BreadCrumbItemComponent_ng_container_3_2_ng_template_0_Template(rf, ctx) {}
function BreadCrumbItemComponent_ng_container_3_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, BreadCrumbItemComponent_ng_container_3_2_ng_template_0_Template, 0, 0, "ng-template", 10);
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.itemTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction2(2, _c10, ctx_r2.item.data, ctx_r2.index));
  }
}
function BreadCrumbItemComponent_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, BreadCrumbItemComponent_ng_container_3_span_1_Template, 5, 14, "span", 3)(2, BreadCrumbItemComponent_ng_container_3_2_Template, 1, 5, null, 1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.itemTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.itemTemplate);
  }
}
function BreadCrumbItemComponent_ng_container_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function BreadCrumbItemComponent_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, BreadCrumbItemComponent_ng_container_4_ng_container_1_Template, 1, 0, "ng-container", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const separator_r1 = i0.ɵɵreference(1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", separator_r1);
  }
}
const _c11 = ["kendoBreadCrumbSeparator", ""];
const _c12 = ["kendoBreadCrumbList", ""];
function BreadCrumbListComponent_ng_container_0_li_1_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("icon", ctx_r0.separatorIcon)("svgIcon", ctx_r0.separatorSVGIcon);
  }
}
function BreadCrumbListComponent_ng_container_0_li_1_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("icon", ctx_r0.separatorIcon)("svgIcon", ctx_r0.separatorSVGIcon);
  }
}
function BreadCrumbListComponent_ng_container_0_li_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 3, 0);
    i0.ɵɵtemplate(2, BreadCrumbListComponent_ng_container_0_li_1_span_2_Template, 1, 2, "span", 4)(3, BreadCrumbListComponent_ng_container_0_li_1_span_3_Template, 1, 2, "span", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const item_r3 = ctx_r1.$implicit;
    const i_r4 = ctx_r1.index;
    const isFirst_r5 = ctx_r1.first;
    const isLast_r6 = ctx_r1.last;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("item", item_r3)("index", i_r4)("collapseMode", ctx_r0.collapseMode)("itemTemplate", ctx_r0.itemTemplate);
    i0.ɵɵattribute("data-kendo-breadcrumb-index", i_r4);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.collapseMode === "wrap" && !isFirst_r5);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.collapseMode !== "wrap" && !isLast_r6 && !((item_r3 == null ? null : item_r3.context.collapsed) && (ctx_r0.items[i_r4 + 1] == null ? null : ctx_r0.items[i_r4 + 1].context.collapsed)));
  }
}
function BreadCrumbListComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, BreadCrumbListComponent_ng_container_0_li_1_Template, 4, 7, "li", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const isFirst_r5 = ctx.first;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !(ctx_r0.collapseMode === "wrap" && isFirst_r5) || ctx_r0.isRootItemContainer);
  }
}
const _c13 = ["resizeSensor"];
const _c14 = ["itemsContainer"];
const _c15 = (a0, a1) => ({
  "!k-flex-wrap": a0,
  "k-flex-none": a1
});
function BreadCrumbComponent_ol_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ol", 5, 0);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵlistener("itemClick", function BreadCrumbComponent_ol_0_Template_ol_itemClick_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.itemClick.emit($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("items", i0.ɵɵpipeBind1(2, 5, ctx_r2.firstItem$))("itemTemplate", ctx_r2.itemTemplate == null ? null : ctx_r2.itemTemplate.templateRef)("collapseMode", ctx_r2.collapseMode)("separatorIcon", ctx_r2.separatorIcon)("separatorSVGIcon", ctx_r2.separatorSVGIcon);
  }
}
const packageMetadata = {
  name: '@progress/kendo-angular-navigation',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1724741724,
  version: '16.8.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};
const DEFAULT_THEME_COLOR = 'light';
const DEFAULT_POSITION = 'top';
const DEFAULT_POSITION_MODE = 'static';
/**
 * Represents the [Kendo UI AppBar component for Angular]({% slug overview_appbar %}).
 * Used to display information, actions, branding titles and additional navigation on the current screen.
 */
class AppBarComponent {
  constructor(localizationService, host, renderer) {
    this.localizationService = localizationService;
    this.host = host;
    this.renderer = renderer;
    this.hostClass = true;
    this.rtl = false;
    this._themeColor = DEFAULT_THEME_COLOR;
    this._position = DEFAULT_POSITION;
    this._positionMode = DEFAULT_POSITION_MODE;
    validatePackage(packageMetadata);
    this.dynamicRTLSubscription = this.localizationService.changes.subscribe(({
      rtl
    }) => {
      this.rtl = rtl;
      this.direction = this.rtl ? 'rtl' : 'ltr';
    });
  }
  /**
   * Specifies the position of the AppBar
   * ([see example]({% slug positioning_appbar %}#toc-position)).
   *
   * * The possible values are:
   * * `top` (Default)&mdash;Positions the AppBar at the top of the content.
   *    Setting the `position` property to `top` requires adding the Appbar component before the page content.
   *    The position property applies CSS `top: 0` style in [`fixed mode`](slug:api_navigation_appbarcomponent#toc-positionmode) and also adds a `box-shadow` to the bottom of the AppBar.
   *
   * * `bottom`&mdash;Positions the AppBar at the bottom of the content.
   *    Setting the `position` property to `bottom` requires adding the Appbar component after the page content.
   *   The position property applies CSS `bottom: 0` style in [`fixed mode`](slug:api_navigation_appbarcomponent#toc-positionmode) and also adds a `box-shadow ` to the top of the AppBar.
   *
   */
  set position(position) {
    const newPosition = position ? position : DEFAULT_POSITION;
    this.handleHostClasses(newPosition, this.position);
    this._position = newPosition;
  }
  get position() {
    return this._position;
  }
  /**
   * Specifies the positionMode of the AppBar
   * ([see example](slug:positioning_appbar#toc-position-mode)).
   *
   * * The possible values are:
   * * `static` (Default)&mdash;Does not position the AppBar in any special way. It is positioned according to the normal flow of the page.
   * * `sticky`&mdash;Positions the AppBar based on the user's scroll position. A sticky element toggles between static and fixed CSS [`position`](https://developer.mozilla.org/en-US/docs/Web/CSS/position) property, depending on the scroll position.
   * * `fixed`&mdash;Positions the AppBar relative to the viewport. It always stays in the same place even if the page is scrolled.
   */
  set positionMode(positionMode) {
    const newPositionMode = positionMode ? positionMode : DEFAULT_POSITION_MODE;
    this.handleHostClasses(newPositionMode, this.positionMode);
    this._positionMode = newPositionMode;
  }
  get positionMode() {
    return this._positionMode;
  }
  /**
   * Specifies the theme color of the AppBar.
   * The theme color will be applied as background color of the component.
   *
   *
   * * The possible values are:
   * * `light` (Default)&mdash;Applies coloring based on light theme color.
   * * `dark`&mdash;Applies coloring based on dark theme color.
   * * `inherit`&mdash; Applies inherited coloring value.
   * * `primary`&mdash; Applies primary coloring value.
   *
   */
  set themeColor(themeColor) {
    const newThemeColor = themeColor ? themeColor : DEFAULT_THEME_COLOR;
    this.handleHostClasses(newThemeColor, this.themeColor);
    this._themeColor = newThemeColor;
  }
  get themeColor() {
    return this._themeColor;
  }
  ngAfterViewInit() {
    const stylingOptions = ['position', 'positionMode', 'themeColor'];
    stylingOptions.forEach(input => {
      this.handleHostClasses(this[input]);
    });
  }
  ngOnDestroy() {
    if (this.dynamicRTLSubscription) {
      this.dynamicRTLSubscription.unsubscribe();
    }
  }
  handleHostClasses(newValue, previousValue) {
    const elem = this.host.nativeElement;
    if (previousValue && newValue === previousValue) {
      return;
    }
    if (previousValue) {
      this.renderer.removeClass(elem, `k-appbar-${previousValue}`);
    }
    if (newValue) {
      this.renderer.addClass(elem, `k-appbar-${newValue}`);
    }
  }
}
AppBarComponent.ɵfac = function AppBarComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AppBarComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
};
AppBarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AppBarComponent,
  selectors: [["kendo-appbar"]],
  hostVars: 3,
  hostBindings: function AppBarComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("dir", ctx.direction);
      i0.ɵɵclassProp("k-appbar", ctx.hostClass);
    }
  },
  inputs: {
    position: "position",
    positionMode: "positionMode",
    themeColor: "themeColor"
  },
  exportAs: ["kendoAppBar"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.appbar.component'
  }]), i0.ɵɵStandaloneFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function AppBarComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppBarComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoAppBar',
      selector: 'kendo-appbar',
      template: `
        <ng-content></ng-content>
    `,
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.appbar.component'
      }],
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    hostClass: [{
      type: HostBinding,
      args: ['class.k-appbar']
    }],
    direction: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    position: [{
      type: Input
    }],
    positionMode: [{
      type: Input
    }],
    themeColor: [{
      type: Input
    }]
  });
})();

/**
 * Represents the [Kendo UI AppBarSection component for Angular]({% slug contentarrangement_appbar %}#toc-sections).
 *
 * @example
 *
 * ```ts-no-run
 *  * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-appbar>
 *            <kendo-appbar-section>
 *                <h2>Page Title</h2>
 *            </kendo-appbar-section>
 *        </kendo-appbar>
 *    `
 * })
 * class AppComponent {}
 */
class AppBarSectionComponent {
  constructor() {
    this.hostClass = true;
  }
}
AppBarSectionComponent.ɵfac = function AppBarSectionComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AppBarSectionComponent)();
};
AppBarSectionComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AppBarSectionComponent,
  selectors: [["kendo-appbar-section"]],
  hostVars: 2,
  hostBindings: function AppBarSectionComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("k-appbar-section", ctx.hostClass);
    }
  },
  standalone: true,
  features: [i0.ɵɵStandaloneFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function AppBarSectionComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppBarSectionComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-appbar-section',
      template: `
        <ng-content></ng-content>
    `,
      standalone: true
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class.k-appbar-section']
    }]
  });
})();

/**
 * @hidden
 */
const isPresent = value => value !== null && value !== undefined;
/**
 * @hidden
 */
const outerWidth = element => {
  const style = getComputedStyle(element);
  let width = parseFloat(style.width);
  width += (parseFloat(style.marginLeft) || 0) + (parseFloat(style.marginRight) || 0);
  return width;
};
/**
 * @hidden
 */
const getFirstAndLastFocusable = parent => {
  const all = getAllFocusableChildren(parent);
  const firstFocusable = all.length > 0 ? all[0] : parent;
  const lastFocusable = all.length > 0 ? all[all.length - 1] : parent;
  return [firstFocusable, lastFocusable];
};
/**
 * @hidden
 */
const getAllFocusableChildren = parent => {
  return parent.querySelectorAll(focusableSelector);
};
/**
 * @hidden
 */
let idx = 0;
/**
 * @hidden
 */
const hexColorRegex = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
/**
 * @hidden
 */
const getId = prefix => {
  return `${prefix}${++idx}`;
};
/**
 * @hidden
 */
const ACTIONSHEET_ITEM_INDEX_ATTRIBUTE = 'kendo-actionsheet-item-index';
/**
 * @hidden
 */
const getActionSheetItemIndex = (target, targetAttr, scope) => {
  const item = closestItem$1(target, targetAttr, scope);
  if (item) {
    return itemIndex$1(item, targetAttr);
  }
};
const itemIndex$1 = (item, indexAttr) => +item.getAttribute(indexAttr);
const hasItemIndex$1 = (item, indexAttr) => isPresent(item.getAttribute(indexAttr));
const closestItem$1 = (target, targetAttr, scope) => closestInScope$1(target, el => hasItemIndex$1(el, targetAttr), scope);

/**
 * Represents the [Kendo UI AppBarSpacer component for Angular]({% slug contentarrangement_appbar %}#toc-spacings).
 * Used to give additional white space between the AppBar sections and provides a way for customizing its width.
 *
 * @example
 *
 * ```ts-no-run
 *  * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-appbar>
 *            <kendo-appbar-section>
 *                 <button kendoButton fillMode="flat">
 *                     <kendo-icon name="menu"></kendo-icon>
 *                 </button>
 *            </kendo-appbar-section>
 *
 *            <kendo-appbar-spacer></kendo-appbar-spacer>
 *
 *            <kendo-appbar-section>
 *                <h2>Page Title</h2>
 *            </kendo-appbar-section>
 *        </kendo-appbar>
 *    `
 * })
 * class AppComponent {}
 * ```
 */
class AppBarSpacerComponent {
  constructor(renderer, element) {
    this.renderer = renderer;
    this.element = element;
    this.hostClass = true;
  }
  get sizedClass() {
    return isPresent(this.width);
  }
  ngAfterViewInit() {
    if (isPresent(this.width)) {
      const element = this.element.nativeElement;
      this.renderer.setStyle(element, 'flexBasis', this.width);
    }
  }
}
AppBarSpacerComponent.ɵfac = function AppBarSpacerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AppBarSpacerComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
AppBarSpacerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AppBarSpacerComponent,
  selectors: [["kendo-appbar-spacer"]],
  hostVars: 4,
  hostBindings: function AppBarSpacerComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("k-spacer", ctx.hostClass)("k-spacer-sized", ctx.sizedClass);
    }
  },
  inputs: {
    width: "width"
  },
  standalone: true,
  features: [i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function AppBarSpacerComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppBarSpacerComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-appbar-spacer',
      template: ``,
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    hostClass: [{
      type: HostBinding,
      args: ['class.k-spacer']
    }],
    sizedClass: [{
      type: HostBinding,
      args: ['class.k-spacer-sized']
    }],
    width: [{
      type: Input
    }]
  });
})();

/**
 * Arguments for the `itemClick` event of the ActionSheet.
 */
class ActionSheetItemClickEvent {}

/**
 * Represents a template that defines the header content of the ActionSheet. Utilizing the template overrides both the `title` and `subtitle` of the ActionSheet.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoActionSheetHeaderTemplate` directive inside the `<kendo-actionsheet>` tag.
 */
class ActionSheetHeaderTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
ActionSheetHeaderTemplateDirective.ɵfac = function ActionSheetHeaderTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActionSheetHeaderTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
ActionSheetHeaderTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ActionSheetHeaderTemplateDirective,
  selectors: [["", "kendoActionSheetHeaderTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionSheetHeaderTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoActionSheetHeaderTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents a template that defines the item content of the ActionSheet.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoActionSheetItemTemplate` directive inside the `<kendo-actionsheet>` tag.
 */
class ActionSheetItemTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
ActionSheetItemTemplateDirective.ɵfac = function ActionSheetItemTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActionSheetItemTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
ActionSheetItemTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ActionSheetItemTemplateDirective,
  selectors: [["", "kendoActionSheetItemTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionSheetItemTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoActionSheetItemTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents a template that defines the items list content of the ActionSheet.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoActionSheetContentTemplate` directive inside the `<kendo-actionsheet>` tag.
 */
class ActionSheetContentTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
ActionSheetContentTemplateDirective.ɵfac = function ActionSheetContentTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActionSheetContentTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
ActionSheetContentTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ActionSheetContentTemplateDirective,
  selectors: [["", "kendoActionSheetContentTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionSheetContentTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoActionSheetContentTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents a template that defines the footer of the ActionSheet.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoActionSheetFooterTemplate` directive inside the `<kendo-actionsheet>` tag.
 */
class ActionSheetFooterTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
ActionSheetFooterTemplateDirective.ɵfac = function ActionSheetFooterTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActionSheetFooterTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
ActionSheetFooterTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ActionSheetFooterTemplateDirective,
  selectors: [["", "kendoActionSheetFooterTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionSheetFooterTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoActionSheetFooterTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents a template that defines the content of the ActionSheet.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoActionSheetTemplate` directive inside the `<kendo-actionsheet>` tag.
 */
class ActionSheetTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
ActionSheetTemplateDirective.ɵfac = function ActionSheetTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActionSheetTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
ActionSheetTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ActionSheetTemplateDirective,
  selectors: [["", "kendoActionSheetTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionSheetTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoActionSheetTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * @hidden
 */
function slideUp(duration, height) {
  return [style({
    overflow: 'hidden',
    display: 'block',
    height: 0
  }), animate(`${duration}ms ease-in`, style({
    height: `${height}`
  }))];
}
/**
 * @hidden
 */
function slideDown(duration, height) {
  return [style({
    overflow: 'hidden',
    height: `${height}`
  }), animate(`${duration}ms ease-in`, style({
    overflow: 'hidden',
    height: 0
  }))];
}

/**
 * @hidden
 */
class ActionSheetItemComponent {
  constructor() {
    this.pointerClass = true;
  }
  manageIconClasses(item) {
    const classes = ['k-actionsheet-item-icon'];
    const isHexColor = isPresent(item.iconColor) && hexColorRegex.test(item.iconColor);
    const isThemeColor = isPresent(item.iconColor) && item.iconColor !== '' && !isHexColor;
    if (isThemeColor) {
      classes.push(`k-text-${item.iconColor}`);
    }
    return classes.join(' ');
  }
  manageIconStyles(item) {
    const isHexColor = isPresent(item.iconColor) && hexColorRegex.test(item.iconColor);
    const isSizeSet = isPresent(item.iconSize) && item.iconSize !== '';
    const styles = {};
    if (isHexColor) {
      styles.color = item.iconColor;
    }
    if (isSizeSet) {
      styles.fontSize = item.iconSize;
    }
    return styles;
  }
}
ActionSheetItemComponent.ɵfac = function ActionSheetItemComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActionSheetItemComponent)();
};
ActionSheetItemComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ActionSheetItemComponent,
  selectors: [["", "kendoActionSheetItem", ""]],
  hostVars: 2,
  hostBindings: function ActionSheetItemComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("k-cursor-pointer", ctx.pointerClass);
    }
  },
  inputs: {
    itemTemplate: "itemTemplate",
    item: "item"
  },
  standalone: true,
  features: [i0.ɵɵStandaloneFeature],
  attrs: _c1,
  decls: 3,
  vars: 2,
  consts: [["defaultTemplate", ""], [4, "ngIf", "ngIfElse"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "k-actionsheet-action"], ["class", "k-icon-wrap", 4, "ngIf"], ["class", "k-actionsheet-item-text", 4, "ngIf"], [1, "k-icon-wrap"], [3, "name", "customFontClass", "svgIcon"], [1, "k-actionsheet-item-text"], ["class", "k-actionsheet-item-title", 4, "ngIf"], ["class", "k-actionsheet-item-description", 4, "ngIf"], [1, "k-actionsheet-item-title"], [1, "k-actionsheet-item-description"]],
  template: function ActionSheetItemComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ActionSheetItemComponent_0_Template, 1, 4, null, 1)(1, ActionSheetItemComponent_ng_template_1_Template, 3, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const defaultTemplate_r2 = i0.ɵɵreference(2);
      i0.ɵɵproperty("ngIf", ctx.itemTemplate)("ngIfElse", defaultTemplate_r2);
    }
  },
  dependencies: [NgIf, NgTemplateOutlet, IconWrapperComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionSheetItemComponent, [{
    type: Component,
    args: [{
      // eslint-disable-next-line @angular-eslint/component-selector
      selector: '[kendoActionSheetItem]',
      template: `
        <ng-template *ngIf="itemTemplate; else defaultTemplate"
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{
                $implicit: item
            }">
        </ng-template>
        <ng-template #defaultTemplate>
            <span class="k-actionsheet-action">
                <span *ngIf="item.icon || item.iconClass || item.svgIcon" class="k-icon-wrap">
                    <kendo-icon-wrapper
                        [name]="item.icon"
                        [customFontClass]="item.iconClass"
                        [class]="manageIconClasses(item)"
                        [svgIcon]="item.svgIcon"
                        [style]="manageIconStyles(item)"
                    >
                    </kendo-icon-wrapper>
                </span>
                <span *ngIf="item.title || item.description" class="k-actionsheet-item-text">
                    <span *ngIf="item.title" class="k-actionsheet-item-title">{{item.title}}</span>
                    <span *ngIf="item.description" class="k-actionsheet-item-description">{{item.description}}</span>
                </span>
            </span>
        </ng-template>
    `,
      standalone: true,
      imports: [NgIf, NgTemplateOutlet, IconWrapperComponent]
    }]
  }], null, {
    itemTemplate: [{
      type: Input
    }],
    item: [{
      type: Input
    }],
    pointerClass: [{
      type: HostBinding,
      args: ['class.k-cursor-pointer']
    }]
  });
})();

/**
 * @hidden
 */
class ActionSheetListComponent {
  constructor(renderer, ngZone, element) {
    this.renderer = renderer;
    this.ngZone = ngZone;
    this.element = element;
    this.groupItems = [];
    this.allItems = [];
    this.itemClick = new EventEmitter();
    this.subscriptions = new Subscription();
  }
  ngAfterViewInit() {
    this.initDomEvents();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  initDomEvents() {
    if (!this.element) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      const nativeElement = this.element.nativeElement;
      this.subscriptions.add(this.renderer.listen(nativeElement, 'click', this.clickHandler.bind(this)));
    });
  }
  clickHandler(e) {
    const itemIndex = getActionSheetItemIndex(e.target, ACTIONSHEET_ITEM_INDEX_ATTRIBUTE, this.element.nativeElement);
    const item = this.allItems[itemIndex];
    if (!item) {
      return;
    }
    if (item.disabled) {
      e.preventDefault();
      return;
    }
    this.ngZone.run(() => {
      this.itemClick.emit({
        item,
        originalEvent: e
      });
    });
  }
  setAttrIndex(item) {
    return this.allItems.indexOf(item);
  }
}
ActionSheetListComponent.ɵfac = function ActionSheetListComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActionSheetListComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ElementRef));
};
ActionSheetListComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ActionSheetListComponent,
  selectors: [["", "kendoActionSheetList", ""]],
  inputs: {
    groupItems: "groupItems",
    allItems: "allItems",
    itemTemplate: "itemTemplate"
  },
  outputs: {
    itemClick: "itemClick"
  },
  standalone: true,
  features: [i0.ɵɵStandaloneFeature],
  attrs: _c3,
  decls: 1,
  vars: 1,
  consts: [["kendoActionSheetItem", "", "tabindex", "0", "role", "button", 3, "k-actionsheet-item", "k-disabled", "ngClass", "ngStyle", "itemTemplate", "item", 4, "ngFor", "ngForOf"], ["kendoActionSheetItem", "", "tabindex", "0", "role", "button", 3, "ngClass", "ngStyle", "itemTemplate", "item"]],
  template: function ActionSheetListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ActionSheetListComponent_span_0_Template, 1, 10, "span", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngForOf", ctx.groupItems);
    }
  },
  dependencies: [NgFor, ActionSheetItemComponent, NgClass, NgStyle],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionSheetListComponent, [{
    type: Component,
    args: [{
      // eslint-disable-next-line @angular-eslint/component-selector
      selector: '[kendoActionSheetList]',
      template: `
            <span *ngFor="let item of groupItems" kendoActionSheetItem
                tabindex="0"
                role="button"
                [attr.aria-disabled]="item.disabled"
                [class.k-actionsheet-item]="true"
                [attr.${ACTIONSHEET_ITEM_INDEX_ATTRIBUTE}]="setAttrIndex(item)"
                [class.k-disabled]="item.disabled"
                [ngClass]="item.cssClass"
                [ngStyle]="item.cssStyle"
                [itemTemplate]="itemTemplate"
                [item]="item">
            </span>
    `,
      standalone: true,
      imports: [NgFor, ActionSheetItemComponent, NgClass, NgStyle]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }, {
      type: i0.ElementRef
    }];
  }, {
    groupItems: [{
      type: Input
    }],
    allItems: [{
      type: Input
    }],
    itemTemplate: [{
      type: Input
    }],
    itemClick: [{
      type: Output
    }]
  });
})();
const DEFAULT_ANIMATION_CONFIG = {
  duration: 300
};
/**
 * Represents the [Kendo UI ActionSheet component for Angular]({% slug overview_actionsheet %}).
 * Used to display a set of choices related to a task the user initiates.
 */
class ActionSheetComponent {
  constructor(element, ngZone, renderer, localizationService, builder, cdr) {
    this.element = element;
    this.ngZone = ngZone;
    this.renderer = renderer;
    this.localizationService = localizationService;
    this.builder = builder;
    this.cdr = cdr;
    /**
     * Configures the ActionSheet opening and closing animations ([see example]({% slug animations_actionsheet %})).
     * By default the animations are turned off. The default animations' duration is `300ms`.
     *
     * @default true
     */
    this.animation = true;
    /**
     * Specifies the state of the ActionSheet.
     *
     * @default false
     */
    this.expanded = false;
    /**
     * Sets the `aria-labelledby` attribute of the ActionSheet wrapper element.
     * Use this option when the built-in header element is replaced through the [`ActionSheetHeaderTemplate`]({% slug api_navigation_actionsheetheadertemplatedirective %})
     * or [`ActionSheetContentTemplate`]({% slug api_navigation_actionsheetcontenttemplatedirective %}).
     *
     */
    this.titleId = getId('k-actionsheet-title');
    /**
     * Fires when the `expanded` property of the component is updated.
     * Used to provide a two-way binding for the `expanded` property.
     */
    this.expandedChange = new EventEmitter();
    /**
     * Fires when the ActionSheet is expanded and its animation is complete.
     */
    this.expand = new EventEmitter();
    /**
     * Fires when the ActionSheet is collapsed and its animation is complete.
     */
    this.collapse = new EventEmitter();
    /**
     * Fires when an ActionSheet item is clicked.
     */
    this.itemClick = new EventEmitter();
    /**
     * Fires when the modal overlay is clicked.
     */
    this.overlayClick = new EventEmitter();
    this.rtl = false;
    this.domSubs = new Subscription();
    this.animationEnd = new EventEmitter();
    validatePackage(packageMetadata);
    this.dynamicRTLSubscription = this.localizationService.changes.subscribe(({
      rtl
    }) => {
      this.rtl = rtl;
      this.direction = this.rtl ? 'rtl' : 'ltr';
    });
  }
  /**
   * @hidden
   */
  get hostClass() {
    return this.expanded;
  }
  ngAfterViewInit() {
    this.initDomEvents();
    this.setCssVariables();
  }
  ngOnChanges(changes) {
    if (changes['expanded'] && this.expanded) {
      this.setExpanded(true);
    }
  }
  ngOnDestroy() {
    this.domSubs.unsubscribe();
    if (this.dynamicRTLSubscription) {
      this.dynamicRTLSubscription.unsubscribe();
    }
    if (this.player) {
      this.player.destroy();
    }
  }
  /**
   * Toggles the visibility of the ActionSheet.
   *
   * @param expanded? - Boolean. Specifies if the ActionSheet will be expanded or collapsed.
   */
  toggle(expanded) {
    const previous = this.expanded;
    const current = isPresent$1(expanded) ? expanded : !previous;
    if (current === previous) {
      return;
    }
    if (current === true) {
      this.setExpanded(true);
    } else if (current === false && !this.animation) {
      this.setExpanded(false);
    }
    if (this.animation) {
      this.animationEnd.pipe(take(1)).subscribe(() => {
        this.onAnimationEnd(current);
      });
      this.playAnimation(current);
    } else {
      this[current ? 'expand' : 'collapse'].emit();
    }
  }
  /**
   * @hidden
   */
  get topGroupItems() {
    return this.items?.filter(item => !item.group || item.group === 'top');
  }
  /**
   * @hidden
   */
  get bottomGroupItems() {
    return this.items?.filter(item => item.group === 'bottom');
  }
  /**
   * @hidden
   */
  onItemClick(ev) {
    this.itemClick.emit(ev);
  }
  /**
   * @hidden
   */
  onOverlayClick() {
    this.overlayClick.emit();
  }
  /**
   * @hidden
   */
  get shouldRenderSeparator() {
    return this.topGroupItems?.length > 0 && this.bottomGroupItems?.length > 0;
  }
  initDomEvents() {
    if (!this.element) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.domSubs.add(this.renderer.listen(this.element.nativeElement, 'keydown', ev => {
        this.onKeyDown(ev);
      }));
    });
  }
  setCssVariables() {
    if (!this.element || !isDocumentAvailable()) {
      return;
    }
    // The following syntax is used as it does not violate CSP compliance
    this.element.nativeElement.style.setProperty('--kendo-actionsheet-height', 'auto');
    this.element.nativeElement.style.setProperty('--kendo-actionsheet-max-height', 'none');
  }
  onKeyDown(event) {
    const target = event.target;
    if (event.keyCode === Keys.Tab) {
      this.ngZone.run(() => {
        this.keepFocusWithinComponent(target, event);
      });
    }
    if (event.keyCode === Keys.Escape) {
      this.ngZone.run(() => {
        this.overlayClick.emit();
      });
    }
    if (event.keyCode === Keys.Enter) {
      this.ngZone.run(() => {
        this.triggerItemClick(target, event);
      });
    }
  }
  handleInitialFocus() {
    const [firstFocusable] = getFirstAndLastFocusable(this.element.nativeElement);
    if (firstFocusable) {
      firstFocusable.focus();
    }
  }
  keepFocusWithinComponent(target, event) {
    const wrapper = this.element.nativeElement;
    const [firstFocusable, lastFocusable] = getFirstAndLastFocusable(wrapper);
    const tabAfterLastFocusable = !event.shiftKey && target === lastFocusable;
    const shiftTabAfterFirstFocusable = event.shiftKey && target === firstFocusable;
    if (tabAfterLastFocusable) {
      event.preventDefault();
      firstFocusable.focus();
    }
    if (shiftTabAfterFirstFocusable) {
      event.preventDefault();
      lastFocusable.focus();
    }
  }
  triggerItemClick(target, event) {
    const itemIndex = getActionSheetItemIndex(target, ACTIONSHEET_ITEM_INDEX_ATTRIBUTE, this.element.nativeElement);
    const item = isPresent$1(itemIndex) ? this.items[itemIndex] : null;
    if (!item || item.disabled) {
      return;
    }
    this.itemClick.emit({
      item,
      originalEvent: event
    });
  }
  setExpanded(value) {
    this.expanded = value;
    this.expandedChange.emit(value);
    if (this.expanded) {
      this.cdr.detectChanges();
      this.handleInitialFocus();
    }
  }
  onAnimationEnd(currentExpanded) {
    if (currentExpanded) {
      this.expand.emit();
    } else {
      this.setExpanded(false);
      this.collapse.emit();
    }
  }
  playAnimation(expanded) {
    const duration = typeof this.animation !== 'boolean' && this.animation.duration ? this.animation.duration : DEFAULT_ANIMATION_CONFIG.duration;
    const contentHeight = getComputedStyle(this.childContainer.nativeElement).height;
    const animation = expanded ? slideUp(duration, contentHeight) : slideDown(duration, contentHeight);
    const factory = this.builder.build(animation);
    this.player = factory.create(this.childContainer.nativeElement);
    this.player.onDone(() => {
      if (this.player) {
        this.animationEnd.emit();
        this.player.destroy();
        this.player = null;
      }
    });
    this.player.play();
  }
}
ActionSheetComponent.ɵfac = function ActionSheetComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActionSheetComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i2.AnimationBuilder), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
ActionSheetComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ActionSheetComponent,
  selectors: [["kendo-actionsheet"]],
  contentQueries: function ActionSheetComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ActionSheetTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, ActionSheetHeaderTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, ActionSheetContentTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, ActionSheetItemTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, ActionSheetFooterTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.actionSheetTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
    }
  },
  viewQuery: function ActionSheetComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c4, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childContainer = _t.first);
    }
  },
  hostVars: 3,
  hostBindings: function ActionSheetComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("dir", ctx.direction);
      i0.ɵɵclassProp("k-actionsheet-container", ctx.hostClass);
    }
  },
  inputs: {
    title: "title",
    subtitle: "subtitle",
    items: "items",
    cssClass: "cssClass",
    animation: "animation",
    expanded: "expanded",
    titleId: "titleId"
  },
  outputs: {
    expandedChange: "expandedChange",
    expand: "expand",
    collapse: "collapse",
    itemClick: "itemClick",
    overlayClick: "overlayClick"
  },
  exportAs: ["kendoActionSheet"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.actionsheet.component'
  }]), i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
  decls: 1,
  vars: 1,
  consts: [["childContainer", ""], ["defaultTemplate", ""], ["defaultHeaderTemplate", ""], ["defaultContentTemplate", ""], [4, "ngIf"], [1, "k-overlay", 3, "click"], [1, "k-animation-container", "k-animation-container-shown"], [1, "k-child-animation-container"], ["role", "dialog", "aria-modal", "true", 1, "k-actionsheet", "k-actionsheet-bottom", 3, "ngClass"], [4, "ngIf", "ngIfElse"], [3, "ngTemplateOutlet"], ["class", "k-actionsheet-titlebar", 4, "ngIf"], ["class", "k-actionsheet-content", 4, "ngIf"], ["class", "k-actionsheet-footer", 4, "ngIf"], [1, "k-actionsheet-titlebar"], [1, "k-actionsheet-titlebar-group", "k-hbox"], [1, "k-actionsheet-title", 3, "id"], ["class", "k-text-center", 4, "ngIf"], ["class", "k-actionsheet-subtitle k-text-center", 4, "ngIf"], [1, "k-text-center"], [1, "k-actionsheet-subtitle", "k-text-center"], [1, "k-actionsheet-content"], ["kendoActionSheetList", "", "class", "k-list-ul", "role", "group", 3, "groupItems", "allItems", "itemTemplate", "itemClick", 4, "ngIf"], ["class", "k-hr", 4, "ngIf"], ["kendoActionSheetList", "", "role", "group", 1, "k-list-ul", 3, "itemClick", "groupItems", "allItems", "itemTemplate"], [1, "k-hr"], [1, "k-actionsheet-footer"]],
  template: function ActionSheetComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, ActionSheetComponent_ng_container_0_Template, 9, 6, "ng-container", 4);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.expanded);
    }
  },
  dependencies: [NgIf, NgClass, NgTemplateOutlet, ActionSheetListComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionSheetComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoActionSheet',
      selector: 'kendo-actionsheet',
      template: `
        <ng-container *ngIf="expanded">
            <div class="k-overlay" (click)="onOverlayClick()"></div>
            <div class="k-animation-container k-animation-container-shown">
                <div #childContainer class="k-child-animation-container" [style]="'bottom: 0px; width: 100%;'">
                    <div class="k-actionsheet k-actionsheet-bottom"
                        [ngClass]="cssClass"
                        role="dialog"
                        aria-modal="true"
                        [attr.aria-labelledby]="titleId">

                        <ng-template *ngIf="actionSheetTemplate; else defaultTemplate"
                            [ngTemplateOutlet]="actionSheetTemplate?.templateRef">
                        </ng-template>

                        <ng-template #defaultTemplate>
                            <div *ngIf="title || subtitle || headerTemplate" class="k-actionsheet-titlebar">
                                <ng-template *ngIf="headerTemplate; else defaultHeaderTemplate"
                                    [ngTemplateOutlet]="headerTemplate?.templateRef">
                                </ng-template>

                                <ng-template #defaultHeaderTemplate>
                                    <div class="k-actionsheet-titlebar-group k-hbox">
                                        <div class="k-actionsheet-title" [id]="titleId">
                                            <div *ngIf="title" class="k-text-center">{{title}}</div>
                                            <div *ngIf="subtitle" class="k-actionsheet-subtitle k-text-center">{{subtitle}}</div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>

                            <div *ngIf="items || contentTemplate" class="k-actionsheet-content">
                                <ng-template *ngIf="contentTemplate; else defaultContentTemplate"
                                    [ngTemplateOutlet]="contentTemplate?.templateRef">
                                </ng-template>
                                <ng-template #defaultContentTemplate>
                                    <div *ngIf="topGroupItems" kendoActionSheetList
                                        class="k-list-ul"
                                        role="group"
                                        [groupItems]="topGroupItems"
                                        [allItems]="items"
                                        [itemTemplate]="itemTemplate?.templateRef"
                                        (itemClick)="onItemClick($event)">
                                    </div>
            
                                    <hr *ngIf="shouldRenderSeparator" class="k-hr"/>
            
                                    <div *ngIf="bottomGroupItems" kendoActionSheetList
                                        class="k-list-ul"
                                        role="group"
                                        [groupItems]="bottomGroupItems"
                                        [allItems]="items"
                                        [itemTemplate]="itemTemplate?.templateRef"
                                        (itemClick)="onItemClick($event)">
                                    </div>
                                </ng-template>
                            </div>
                            <div  *ngIf="footerTemplate" class="k-actionsheet-footer">
                                <ng-template
                                    [ngTemplateOutlet]="footerTemplate?.templateRef">
                                </ng-template>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-container>
    `,
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.actionsheet.component'
      }],
      standalone: true,
      imports: [NgIf, NgClass, NgTemplateOutlet, ActionSheetListComponent]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Renderer2
    }, {
      type: i1.LocalizationService
    }, {
      type: i2.AnimationBuilder
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    hostClass: [{
      type: HostBinding,
      args: ['class.k-actionsheet-container']
    }],
    direction: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    title: [{
      type: Input
    }],
    subtitle: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    cssClass: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    expanded: [{
      type: Input
    }],
    titleId: [{
      type: Input
    }],
    expandedChange: [{
      type: Output
    }],
    expand: [{
      type: Output
    }],
    collapse: [{
      type: Output
    }],
    itemClick: [{
      type: Output
    }],
    overlayClick: [{
      type: Output
    }],
    childContainer: [{
      type: ViewChild,
      args: ['childContainer']
    }],
    actionSheetTemplate: [{
      type: ContentChild,
      args: [ActionSheetTemplateDirective]
    }],
    headerTemplate: [{
      type: ContentChild,
      args: [ActionSheetHeaderTemplateDirective]
    }],
    contentTemplate: [{
      type: ContentChild,
      args: [ActionSheetContentTemplateDirective]
    }],
    itemTemplate: [{
      type: ContentChild,
      args: [ActionSheetItemTemplateDirective]
    }],
    footerTemplate: [{
      type: ContentChild,
      args: [ActionSheetFooterTemplateDirective]
    }]
  });
})();

/**
 * @hidden
 */
class PreventableEvent {
  /**
   * @hidden
   */
  constructor(args) {
    this.prevented = false;
    Object.assign(this, args);
  }
  /**
   * Prevents the default action for a specified event.
   * In this way, the source component suppresses
   * the built-in behavior that follows the event.
   */
  preventDefault() {
    this.prevented = true;
  }
  /**
   * Returns `true` if the event was prevented
   * by any of its subscribers.
   *
   * @returns `true` if the default action was prevented.
   * Otherwise, returns `false`.
   */
  isDefaultPrevented() {
    return this.prevented;
  }
}

/**
 * Arguments for the `select` event of the BottomNavigation.
 */
class BottomNavigationSelectEvent extends PreventableEvent {}

/**
 * Represents a template that defines the item content of the BottomNavigation.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoBottomNavigationItemTemplate` directive inside the `<kendo-bottomnavigation>` tag ([see example]({% slug templates_bottomnavigation %})).
 */
class BottomNavigationItemTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
BottomNavigationItemTemplateDirective.ɵfac = function BottomNavigationItemTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BottomNavigationItemTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
BottomNavigationItemTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BottomNavigationItemTemplateDirective,
  selectors: [["", "kendoBottomNavigationItemTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BottomNavigationItemTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoBottomNavigationItemTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();
const closestInScope = (target, targetAttr, predicate, scope) => {
  while (target && target !== scope && !predicate(target, targetAttr)) {
    target = target.parentNode;
  }
  if (target !== scope) {
    return target;
  }
};
const hasItemIndex = (item, indexAttr) => isPresent(item.getAttribute(indexAttr));
/**
 * @hidden
 */
const itemIndex = (item, indexAttr) => +item.getAttribute(indexAttr);
/**
 * @hidden
 */
const closestItem = (target, targetAttr, scope) => closestInScope(target, targetAttr, hasItemIndex, scope);

/**
 * @hidden
 */
const BOTTOMNAVIGATION_ITEM_INDEX = 'data-kendo-bottomnavigation-index';
/**
 * @hidden
 */
const colors = ['primary', 'secondary', 'tertiary', 'info', 'success', 'warning', 'error', 'dark', 'light', 'inverse'];

/**
 * @hidden
 */
class BottomNavigationItemComponent {
  get disabledClass() {
    return this.item.disabled;
  }
  get label() {
    return this.item.text ? this.item.text : null;
  }
  get tabindex() {
    return this.item.tabIndex ? this.item.tabIndex : 0;
  }
  get selectedClass() {
    return this.selectedIdx ? this.selectedIdx === this.index : this.item.selected;
  }
  get itemIcon() {
    return Boolean(this.item.icon || this.item.iconClass || this.item.svgIcon);
  }
  get iconClasses() {
    const kendoIcon = this.item.icon ? `k-icon k-i-${this.item.icon}` : '';
    const customIcon = this.item.iconClass ? this.item.iconClass : '';
    return `${kendoIcon} ${customIcon}`;
  }
}
BottomNavigationItemComponent.ɵfac = function BottomNavigationItemComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BottomNavigationItemComponent)();
};
BottomNavigationItemComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BottomNavigationItemComponent,
  selectors: [["", "kendoBottomNavigationItem", ""]],
  hostVars: 8,
  hostBindings: function BottomNavigationItemComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("aria-disabled", ctx.disabledClass)("aria-label", ctx.label)("tabindex", ctx.tabindex)("aria-current", ctx.selectedClass);
      i0.ɵɵclassProp("k-disabled", ctx.disabledClass)("k-selected", ctx.selectedClass);
    }
  },
  inputs: {
    itemTemplate: "itemTemplate",
    item: "item",
    index: "index",
    disabledComponent: "disabledComponent",
    selectedIdx: "selectedIdx",
    orientation: "orientation"
  },
  standalone: true,
  features: [i0.ɵɵStandaloneFeature],
  attrs: _c5,
  decls: 2,
  vars: 2,
  consts: [[4, "ngIf"], ["innerCssClass", "k-bottom-nav-item-icon", "size", "xlarge", 3, "name", "customFontClass", "svgIcon", 4, "ngIf"], ["class", "k-bottom-nav-item-text", 4, "ngIf"], ["innerCssClass", "k-bottom-nav-item-icon", "size", "xlarge", 3, "name", "customFontClass", "svgIcon"], [1, "k-bottom-nav-item-text"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function BottomNavigationItemComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, BottomNavigationItemComponent_ng_container_0_Template, 3, 2, "ng-container", 0)(1, BottomNavigationItemComponent_1_Template, 1, 4, null, 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", !ctx.itemTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.itemTemplate);
    }
  },
  dependencies: [NgIf, IconWrapperComponent, NgTemplateOutlet],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BottomNavigationItemComponent, [{
    type: Component,
    args: [{
      // eslint-disable-next-line @angular-eslint/component-selector
      selector: '[kendoBottomNavigationItem]',
      template: `
        <ng-container *ngIf="!itemTemplate">
            <kendo-icon-wrapper *ngIf="itemIcon"
                innerCssClass="k-bottom-nav-item-icon"
                size="xlarge"
                [name]="item.icon"
                [customFontClass]="item.iconClass"
                [svgIcon]="item.svgIcon"></kendo-icon-wrapper>
            <span *ngIf="item.text" class="k-bottom-nav-item-text">{{item.text}}</span>
        </ng-container>
        <ng-template *ngIf="itemTemplate"
            [ngTemplateOutlet]="itemTemplate?.templateRef"
            [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-template>
    `,
      standalone: true,
      imports: [NgIf, IconWrapperComponent, NgTemplateOutlet]
    }]
  }], null, {
    itemTemplate: [{
      type: Input
    }],
    item: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    disabledComponent: [{
      type: Input
    }],
    selectedIdx: [{
      type: Input
    }],
    orientation: [{
      type: Input
    }],
    disabledClass: [{
      type: HostBinding,
      args: ['attr.aria-disabled']
    }, {
      type: HostBinding,
      args: ['class.k-disabled']
    }],
    label: [{
      type: HostBinding,
      args: ['attr.aria-label']
    }],
    tabindex: [{
      type: HostBinding,
      args: ['attr.tabindex']
    }],
    selectedClass: [{
      type: HostBinding,
      args: ['attr.aria-current']
    }, {
      type: HostBinding,
      args: ['class.k-selected']
    }]
  });
})();

/**
 * Represents the [Kendo UI BottomNavigation component for Angular]({% slug overview_bottomnavigation %}).
 *
 * @example
 * ```ts-no-run
 * _@Component({
 *      selector: 'my-app',
 *      template: `
 *          <kendo-bottomnavigation [items]="items"></kendo-bottomnavigation>
 *      `
 * })
 * class AppComponent {
 *      public items: Array<any> = [
 *          { text: 'Inbox', icon: 'envelop', selected: true },
 *          { text: 'Calendar', icon: 'calendar'},
 *          { text: 'Profile', icon: 'user'}
 *      ]
 * }
 * ```
 */
class BottomNavigationComponent {
  constructor(localization, hostElement, ngZone, changeDetector, renderer) {
    this.localization = localization;
    this.hostElement = hostElement;
    this.ngZone = ngZone;
    this.changeDetector = changeDetector;
    this.renderer = renderer;
    /**
     * Sets a top border to the BottomNavigation ([see example]({% slug appearance_bottomnavigation %})).
     *
     * @default false
     */
    this.border = false;
    /**
     * Disables the whole BottomNavigation.
     *
     * @default false
     */
    this.disabled = false;
    /**
     * Fires each time an item is selected. This event is preventable.
     */
    this.select = new EventEmitter();
    /**
     * @hidden
     */
    this.hostClass = true;
    /**
     * @hidden
     */
    this.role = 'navigation';
    this._fill = 'flat';
    this._itemFlow = 'vertical';
    this._positionMode = 'fixed';
    this._themeColor = 'primary';
    this._nativeHostElement = this.hostElement.nativeElement;
    this.subscriptions = new Subscription();
    this.rtl = false;
    validatePackage(packageMetadata);
    this.dynamicRTLSubscription = this.localization.changes.subscribe(({
      rtl
    }) => {
      this.rtl = rtl;
      this.direction = this.rtl ? 'rtl' : 'ltr';
    });
  }
  /**
   * The fill style of the BottomNavigation ([see example]({% slug appearance_bottomnavigation %})).
   *
   * * The possible values are:
   * * (Default) `flat`
   * * `solid`
   */
  set fill(fill) {
    this.renderer.removeClass(this._nativeHostElement, `k-bottom-nav-${this.fill}`);
    this.renderer.removeClass(this._nativeHostElement, `k-bottom-nav-${this.fill}-${this.themeColor}`);
    this._fill = fill === 'solid' ? 'solid' : 'flat';
    this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-${this._fill}`);
    this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-${this._fill}-${this.themeColor}`);
  }
  get fill() {
    return this._fill;
  }
  /**
   * Specifies how the icon and text label are positioned in the BottomNavigation items.
   *
   * The possible values are:
   * * (Default) `vertical` - Renders the text below the icon.
   * * `horizontal` - Renders the icon and the text on the same line.
   */
  set itemFlow(itemFlow) {
    this.renderer.removeClass(this._nativeHostElement, `k-bottom-nav-item-flow-${this.itemFlow}`);
    this._itemFlow = itemFlow === 'horizontal' ? 'horizontal' : 'vertical';
    this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-item-flow-${this._itemFlow}`);
  }
  get itemFlow() {
    return this._itemFlow;
  }
  /**
   * Specifies the position and behavior of the BottomNavigation when the page is scrollable ([see example]({% slug positioning_bottomnavigation %})).
   *
   * The possible values are:
   * * (Default) `fixed` - The BottomNavigation always stays at the bottom of the viewport, regardless of the page scroll position.
   * * `sticky` - Positions the BottomNavigation at the end of the scrollable container.
   */
  set positionMode(positionMode) {
    this.renderer.removeClass(this._nativeHostElement, `k-pos-${this.positionMode}`);
    this._positionMode = positionMode === 'sticky' ? 'sticky' : 'fixed';
    this.renderer.addClass(this._nativeHostElement, `k-pos-${this._positionMode}`);
  }
  get positionMode() {
    return this._positionMode;
  }
  /**
   * Specifies the theme color of the BottomNavigation ([see example]({% slug appearance_bottomnavigation %})).
   *
   * * The possible values are:
   * * (Default) `primary` - Applies coloring based on the primary theme color.
   * * `secondary` - Applies coloring based on the secondary theme color.
   * * `tertiary` - Applies coloring based on the tertiary theme color.
   * * `info` - Applies coloring based on the info theme color.
   * * `success` - Applies coloring based on the success theme color.
   * * `warning` - Applies coloring based on the warning theme color.
   * * `error` - Applies coloring based on the error theme color.
   * * `dark` - Applies coloring based on the dark theme color.
   * * `light` - Applies coloring based on the light theme color.
   * * `inverse` - Applies coloring based on the inverted theme color.
   */
  set themeColor(themeColor) {
    const newColor = colors.find(color => color === themeColor);
    if (newColor) {
      this.renderer.removeClass(this._nativeHostElement, `k-bottom-nav-${this.fill}-${this._themeColor}`);
      this._themeColor = themeColor;
      this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-${this.fill}-${this._themeColor}`);
    }
  }
  get themeColor() {
    return this._themeColor;
  }
  /**
   * @hidden
   */
  get borderClass() {
    return this.border;
  }
  /**
   * @hidden
   */
  get disabledClass() {
    return this.disabled;
  }
  /**
   * @hidden
   */
  ngOnInit() {
    this.initDomEvents();
  }
  /**
   * @hidden
   */
  ngAfterViewInit() {
    this.applyClasses();
  }
  /**
   * @hidden
   */
  ngOnDestroy() {
    if (this.dynamicRTLSubscription) {
      this.dynamicRTLSubscription.unsubscribe();
    }
    this.subscriptions.unsubscribe();
  }
  /**
   * @hidden
   */
  selectItem(idx, args) {
    const eventArgs = new BottomNavigationSelectEvent({
      ...args
    });
    this.select.emit(eventArgs);
    if (!eventArgs.isDefaultPrevented()) {
      this.selectedIdx = idx;
    }
  }
  applyClasses() {
    this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-${this.fill}`);
    this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-item-flow-${this.itemFlow}`);
    this.renderer.addClass(this._nativeHostElement, `k-pos-${this.positionMode}`);
    this.renderer.addClass(this._nativeHostElement, `k-bottom-nav-${this.fill}-${this.themeColor}`);
  }
  initDomEvents() {
    if (!this.hostElement) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.subscriptions.add(this.renderer.listen(this._nativeHostElement, 'click', this.clickHandler.bind(this)));
      this.subscriptions.add(this.renderer.listen(this._nativeHostElement, 'keydown', this.keyDownHandler.bind(this)));
    });
  }
  clickHandler(e) {
    const itemIdx = this.getBottomNavigationItemIndex(e.target);
    const item = this.items[itemIdx];
    if (!item) {
      return;
    }
    if (item.disabled) {
      e.preventDefault();
      return;
    }
    const args = {
      index: itemIdx,
      item: item,
      originalEvent: e,
      sender: this
    };
    this.ngZone.run(() => {
      this.selectItem(itemIdx, args);
      this.changeDetector.markForCheck();
    });
  }
  keyDownHandler(e) {
    const isEnterOrSpace = e.keyCode === Keys.Enter || e.keyCode === Keys.Space;
    if (!isEnterOrSpace) {
      return;
    }
    this.clickHandler(e);
  }
  getBottomNavigationItemIndex(target) {
    const item = closestItem(target, BOTTOMNAVIGATION_ITEM_INDEX, this._nativeHostElement);
    if (item) {
      return itemIndex(item, BOTTOMNAVIGATION_ITEM_INDEX);
    }
  }
}
BottomNavigationComponent.ɵfac = function BottomNavigationComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BottomNavigationComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.Renderer2));
};
BottomNavigationComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BottomNavigationComponent,
  selectors: [["kendo-bottomnavigation"]],
  contentQueries: function BottomNavigationComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, BottomNavigationItemTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
    }
  },
  hostVars: 8,
  hostBindings: function BottomNavigationComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.role)("dir", ctx.direction);
      i0.ɵɵclassProp("k-bottom-nav", ctx.hostClass)("k-bottom-nav-border", ctx.borderClass)("k-disabled", ctx.disabledClass);
    }
  },
  inputs: {
    items: "items",
    border: "border",
    disabled: "disabled",
    fill: "fill",
    itemFlow: "itemFlow",
    positionMode: "positionMode",
    themeColor: "themeColor"
  },
  outputs: {
    select: "select"
  },
  exportAs: ["kendoBottomNavigation"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.bottomnavigation'
  }]), i0.ɵɵStandaloneFeature],
  decls: 1,
  vars: 1,
  consts: [[4, "ngIf"], ["kendoBottomNavigationItem", "", "role", "link", "class", "k-bottom-nav-item", 3, "disabledComponent", "item", "index", "selectedIdx", "itemTemplate", "ngClass", "ngStyle", "orientation", 4, "ngFor", "ngForOf"], ["kendoBottomNavigationItem", "", "role", "link", 1, "k-bottom-nav-item", 3, "disabledComponent", "item", "index", "selectedIdx", "itemTemplate", "ngClass", "ngStyle", "orientation"]],
  template: function BottomNavigationComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, BottomNavigationComponent_ng_container_0_Template, 2, 1, "ng-container", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.items);
    }
  },
  dependencies: [NgIf, NgFor, BottomNavigationItemComponent, NgClass, NgStyle],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BottomNavigationComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoBottomNavigation',
      selector: 'kendo-bottomnavigation',
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.bottomnavigation'
      }],
      template: `
        <ng-container *ngIf="items">
            <span kendoBottomNavigationItem
                *ngFor="let item of items; let idx=index"
                role="link"
                class="k-bottom-nav-item"
                [disabledComponent]="disabled"
                [item]="item"
                [index]="idx"
                [selectedIdx]="selectedIdx"
                [itemTemplate]="itemTemplate"
                [attr.${BOTTOMNAVIGATION_ITEM_INDEX}]="idx"
                [ngClass]="item.cssClass"
                [ngStyle]="item.cssStyle"
                [orientation]="itemFlow">
            </span>
        </ng-container>
    `,
      standalone: true,
      imports: [NgIf, NgFor, BottomNavigationItemComponent, NgClass, NgStyle]
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    items: [{
      type: Input
    }],
    border: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    fill: [{
      type: Input
    }],
    itemFlow: [{
      type: Input
    }],
    positionMode: [{
      type: Input
    }],
    themeColor: [{
      type: Input
    }],
    select: [{
      type: Output
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class.k-bottom-nav']
    }],
    borderClass: [{
      type: HostBinding,
      args: ['class.k-bottom-nav-border']
    }],
    disabledClass: [{
      type: HostBinding,
      args: ['class.k-disabled']
    }],
    role: [{
      type: HostBinding,
      args: ['attr.role']
    }],
    direction: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: [BottomNavigationItemTemplateDirective, {
        static: false
      }]
    }]
  });
})();

/**
 * Represents a template that defines the content of a Breadcrumb item.
 * To define the template, nest an `<ng-template>` tag with the `kendoBreadCrumbItemTemplate` directive inside the `<kendo-breadcrumb>` tag.
 *
 * For more information and example refer to the [Templates]({% slug templates_breadcrumb %}) article.
 */
class BreadCrumbItemTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
BreadCrumbItemTemplateDirective.ɵfac = function BreadCrumbItemTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadCrumbItemTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
BreadCrumbItemTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BreadCrumbItemTemplateDirective,
  selectors: [["", "kendoBreadCrumbItemTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadCrumbItemTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoBreadCrumbItemTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * @hidden
 */
const BREADCRUMB_ITEM_INDEX = 'data-kendo-breadcrumb-index';
/**
 * @hidden
 */
const DEFAULT_SIZE = 'medium';
const SIZES = {
  small: 'sm',
  medium: 'md',
  large: 'lg'
};
/**
 * @hidden
 *
 * Returns the styling classes to be added and removed
 */
const getStylingClasses = (stylingOption, previousValue, newValue) => {
  switch (stylingOption) {
    case 'size':
      return {
        toRemove: `k-breadcrumb-${SIZES[previousValue]}`,
        toAdd: newValue !== 'none' ? `k-breadcrumb-${SIZES[newValue]}` : ''
      };
    default:
      break;
  }
};

/**
 * @hidden
 */
class BreadCrumbItemComponent {
  constructor(el) {
    this.el = el;
    this.index = -1;
    this.hostClasses = true;
    this.disabled = false;
  }
  get isRootItem() {
    return this.item.context.isFirst;
  }
  get isDisabled() {
    return this.disabled || null;
  }
  get isLastItem() {
    return this.item.context.isLast;
  }
  ngOnInit() {
    this.disabled = this.item.data && (this.item.data.disabled || this.item.context.isLast);
  }
  ngAfterViewInit() {
    if (isDocumentAvailable()) {
      this.width = outerWidth(this.el.nativeElement);
    }
  }
  onImageLoad() {
    if (isDocumentAvailable()) {
      this.width = outerWidth(this.el.nativeElement);
    }
  }
}
BreadCrumbItemComponent.ɵfac = function BreadCrumbItemComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadCrumbItemComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
BreadCrumbItemComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BreadCrumbItemComponent,
  selectors: [["", "kendoBreadCrumbItem", ""]],
  hostVars: 7,
  hostBindings: function BreadCrumbItemComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("aria-disabled", ctx.isDisabled);
      i0.ɵɵclassProp("k-breadcrumb-item", ctx.hostClasses)("k-breadcrumb-root-item", ctx.isRootItem)("k-breadcrumb-last-item", ctx.isLastItem);
    }
  },
  inputs: {
    item: "item",
    collapseMode: "collapseMode",
    index: "index",
    itemTemplate: "itemTemplate"
  },
  standalone: true,
  features: [i0.ɵɵStandaloneFeature],
  attrs: _c6,
  ngContentSelectors: _c8,
  decls: 5,
  vars: 3,
  consts: [["separator", ""], [4, "ngIf"], [4, "ngTemplateOutlet"], ["role", "link", 3, "ngClass", "title", "tabindex", 4, "ngIf"], ["role", "link", 3, "ngClass", "title", "tabindex"], ["class", "k-image", "role", "presentation", 3, "src", "load", 4, "ngIf"], [3, "name", "customFontClass", "svgIcon", 4, "ngIf"], [1, "k-breadcrumb-item-text"], ["role", "presentation", 1, "k-image", 3, "load", "src"], [3, "name", "customFontClass", "svgIcon"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function BreadCrumbItemComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c7);
      i0.ɵɵtemplate(0, BreadCrumbItemComponent_ng_template_0_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(2, BreadCrumbItemComponent_ng_container_2_Template, 2, 1, "ng-container", 1)(3, BreadCrumbItemComponent_ng_container_3_Template, 3, 2, "ng-container", 1)(4, BreadCrumbItemComponent_ng_container_4_Template, 2, 1, "ng-container", 1);
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.collapseMode === "wrap");
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.item.context.collapsed);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.collapseMode !== "wrap");
    }
  },
  dependencies: [NgIf, NgTemplateOutlet, NgClass, IconWrapperComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadCrumbItemComponent, [{
    type: Component,
    args: [{
      // eslint-disable-next-line @angular-eslint/component-selector
      selector: '[kendoBreadCrumbItem]',
      template: `
        <ng-template #separator>
            <ng-content select="[kendoBreadCrumbSeparator]"></ng-content>
        </ng-template>

        <ng-container *ngIf="collapseMode === 'wrap'">
            <ng-container *ngTemplateOutlet="separator"></ng-container>
        </ng-container>

        <ng-container *ngIf="!item.context.collapsed">
            <span
                *ngIf="!itemTemplate"
                [ngClass]="{
                    'k-breadcrumb-root-link': item.context.isFirst,
                    'k-breadcrumb-link': index !== 0,
                    'k-breadcrumb-icontext-link': !!item.data.icon && !!item.data.text,
                    'k-breadcrumb-icon-link': !!item.data.icon && !item.data.text,
                    'k-disabled': disabled
                }"
                [title]="item.data.title || ''"
                [tabindex]="disabled ? -1 : 0"
                [attr.aria-disabled]="disabled"
                [attr.aria-current]="item.context.isLast ? 'page' : null"
                role="link"
            >
                <img *ngIf="item.data.imageUrl" (load)="onImageLoad()" [src]="item.data.imageUrl" class="k-image" role="presentation" />
                <kendo-icon-wrapper *ngIf="item.data.icon || item.data.iconClass || item.data.svgIcon"
                    [name]="item.data.icon"
                    [customFontClass]="item.data.iconClass"
                    [svgIcon]="item.data.svgIcon"
                >
                </kendo-icon-wrapper>
                <span class="k-breadcrumb-item-text">{{ item.data.text }}</span>
            </span>
            <ng-template
                *ngIf="itemTemplate"
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{
                    $implicit: item.data,
                    index: index
                }"
            ></ng-template>
        </ng-container>

        <ng-container *ngIf="collapseMode !== 'wrap'">
            <ng-container *ngTemplateOutlet="separator"></ng-container>
        </ng-container>
    `,
      standalone: true,
      imports: [NgIf, NgTemplateOutlet, NgClass, IconWrapperComponent]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    item: [{
      type: Input
    }],
    collapseMode: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    itemTemplate: [{
      type: Input
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class.k-breadcrumb-item']
    }],
    isRootItem: [{
      type: HostBinding,
      args: ['class.k-breadcrumb-root-item']
    }],
    isDisabled: [{
      type: HostBinding,
      args: ['attr.aria-disabled']
    }],
    isLastItem: [{
      type: HostBinding,
      args: ['class.k-breadcrumb-last-item']
    }]
  });
})();
const DEFAULT_ICON = 'chevron-right';
const DEFAULT_RTL_ICON = 'chevron-left';
const DEFAULT_SVG_ICON = chevronRightIcon;
const DEFAULT_RTL_SVG_ICON = chevronLeftIcon;
/**
 * @hidden
 */
class BreadCrumbSeparatorDirective {
  constructor(el, localization) {
    this.el = el;
    this.localization = localization;
    this.defaultClasses = true;
    this.ariaHidden = true;
    this.direction = 'ltr';
    this.direction = this.localization.rtl ? 'rtl' : 'ltr';
  }
  set icon(icon) {
    if (isPresent(icon)) {
      this._icon = icon;
      this.hasDefaultIcon = false;
    } else {
      this._icon = this.direction === 'ltr' ? DEFAULT_ICON : DEFAULT_RTL_ICON;
      this.hasDefaultIcon = true;
    }
  }
  get icon() {
    return this._icon;
  }
  set svgIcon(svgIcon) {
    if (isPresent(svgIcon)) {
      this._svgIcon = svgIcon;
      this.hasDefaultSvgIcon = false;
    } else {
      this._svgIcon = this.direction === 'ltr' ? DEFAULT_SVG_ICON : DEFAULT_RTL_SVG_ICON;
      this.hasDefaultSvgIcon = true;
    }
  }
  get svgIcon() {
    return this._svgIcon;
  }
  ngOnInit() {
    this.localizationChangesSubscription = this.localization.changes.subscribe(({
      rtl
    }) => {
      this.direction = rtl ? 'rtl' : 'ltr';
      if (this.hasDefaultIcon) {
        this.icon = undefined;
      }
      if (this.hasDefaultSvgIcon) {
        this.svgIcon = undefined;
      }
    });
  }
  ngOnDestroy() {
    this.localizationChangesSubscription.unsubscribe();
  }
}
BreadCrumbSeparatorDirective.ɵfac = function BreadCrumbSeparatorDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadCrumbSeparatorDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.LocalizationService));
};
BreadCrumbSeparatorDirective.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BreadCrumbSeparatorDirective,
  selectors: [["", "kendoBreadCrumbSeparator", ""]],
  hostVars: 5,
  hostBindings: function BreadCrumbSeparatorDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("aria-hidden", ctx.ariaHidden);
      i0.ɵɵclassProp("k-breadcrumb-delimiter-icon", ctx.defaultClasses)("k-icon", ctx.defaultClasses);
    }
  },
  inputs: {
    icon: "icon",
    svgIcon: "svgIcon"
  },
  standalone: true,
  features: [i0.ɵɵStandaloneFeature],
  attrs: _c11,
  decls: 1,
  vars: 2,
  consts: [["size", "xsmall", 3, "name", "svgIcon"]],
  template: function BreadCrumbSeparatorDirective_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "kendo-icon-wrapper", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("name", ctx.icon)("svgIcon", ctx.svgIcon);
    }
  },
  dependencies: [IconWrapperComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadCrumbSeparatorDirective, [{
    type: Component,
    args: [{
      selector: '[kendoBreadCrumbSeparator]',
      template: `
        <kendo-icon-wrapper
            size='xsmall'
            [name]="icon"
            [svgIcon]="svgIcon"
        >
        </kendo-icon-wrapper>
    `,
      standalone: true,
      imports: [IconWrapperComponent]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1.LocalizationService
    }];
  }, {
    icon: [{
      type: Input
    }],
    svgIcon: [{
      type: Input
    }],
    defaultClasses: [{
      type: HostBinding,
      args: ['class.k-breadcrumb-delimiter-icon']
    }, {
      type: HostBinding,
      args: ['class.k-icon']
    }],
    ariaHidden: [{
      type: HostBinding,
      args: ['attr.aria-hidden']
    }]
  });
})();

/**
 * @hidden
 */
class BreadCrumbListComponent {
  constructor(el, zone) {
    this.el = el;
    this.zone = zone;
    this.items = [];
    this.collapseMode = 'auto';
    this.itemClick = new EventEmitter();
    const element = this.el.nativeElement;
    this.isRootItemContainer = element.classList.contains('k-breadcrumb-root-item-container');
    this.zone.runOutsideAngular(() => {
      const click$ = fromEvent(element, 'click');
      const enterKey$ = fromEvent(element, 'keydown').pipe(filter((ev /* KeyboardEvent causes lint error */) => ev.keyCode === Keys.Enter));
      this.domEventsSubscription = merge(click$, enterKey$).pipe(map(ev => ev.target), filter(e => !e.classList.contains('k-breadcrumb-delimiter-icon')),
      // do not trigger handler when a separator is clicked
      map(e => this.getItemIndex(e)), filter(isPresent), map(i => parseInt(i, 10)), map(i => this.items[i]), filter(item => !item.data.disabled && !item.context.isLast), map(item => item.data)).subscribe(el => {
        this.zone.run(() => this.itemClick.emit(el));
      });
    });
  }
  ngOnDestroy() {
    this.domEventsSubscription.unsubscribe();
  }
  getItemIndex(target) {
    const item = closestItem(target, BREADCRUMB_ITEM_INDEX, this.el.nativeElement);
    if (item) {
      return itemIndex(item, BREADCRUMB_ITEM_INDEX);
    }
  }
}
BreadCrumbListComponent.ɵfac = function BreadCrumbListComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadCrumbListComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
BreadCrumbListComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BreadCrumbListComponent,
  selectors: [["", "kendoBreadCrumbList", ""]],
  viewQuery: function BreadCrumbListComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(BreadCrumbItemComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.renderedItems = _t);
    }
  },
  inputs: {
    items: "items",
    itemTemplate: "itemTemplate",
    collapseMode: "collapseMode",
    separatorIcon: "separatorIcon",
    separatorSVGIcon: "separatorSVGIcon"
  },
  outputs: {
    itemClick: "itemClick"
  },
  standalone: true,
  features: [i0.ɵɵStandaloneFeature],
  attrs: _c12,
  decls: 1,
  vars: 1,
  consts: [["renderedItem", ""], [4, "ngFor", "ngForOf"], ["kendoBreadCrumbItem", "", 3, "item", "index", "collapseMode", "itemTemplate", 4, "ngIf"], ["kendoBreadCrumbItem", "", 3, "item", "index", "collapseMode", "itemTemplate"], ["kendoBreadCrumbSeparator", "", 3, "icon", "svgIcon", 4, "ngIf"], ["kendoBreadCrumbSeparator", "", 3, "icon", "svgIcon"]],
  template: function BreadCrumbListComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, BreadCrumbListComponent_ng_container_0_Template, 2, 1, "ng-container", 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngForOf", ctx.items);
    }
  },
  dependencies: [NgFor, NgIf, BreadCrumbItemComponent, BreadCrumbSeparatorDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadCrumbListComponent, [{
    type: Component,
    args: [{
      // eslint-disable-next-line @angular-eslint/component-selector
      selector: '[kendoBreadCrumbList]',
      template: `
        <ng-container *ngFor="let item of items; let i = index; let isFirst = first; let isLast = last">
            <li
                #renderedItem
                kendoBreadCrumbItem
                *ngIf="!(collapseMode === 'wrap' && isFirst) || isRootItemContainer"
                [attr.${BREADCRUMB_ITEM_INDEX}]="i"
                [item]="item"
                [index]="i"
                [collapseMode]="collapseMode"
                [itemTemplate]="itemTemplate"
            >
                <span kendoBreadCrumbSeparator [icon]="separatorIcon" [svgIcon]="separatorSVGIcon" *ngIf="collapseMode === 'wrap' && !isFirst"></span>
                <span
                    kendoBreadCrumbSeparator
                    [icon]="separatorIcon"
                    [svgIcon]="separatorSVGIcon"
                    *ngIf="collapseMode !== 'wrap' && !isLast && !(item?.context.collapsed && items[i + 1]?.context.collapsed)"
                ></span>
            </li>
        </ng-container>
    `,
      standalone: true,
      imports: [NgFor, NgIf, BreadCrumbItemComponent, BreadCrumbSeparatorDirective]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    items: [{
      type: Input
    }],
    itemTemplate: [{
      type: Input
    }],
    collapseMode: [{
      type: Input
    }],
    separatorIcon: [{
      type: Input
    }],
    separatorSVGIcon: [{
      type: Input
    }],
    itemClick: [{
      type: Output
    }],
    renderedItems: [{
      type: ViewChildren,
      args: [BreadCrumbItemComponent]
    }]
  });
})();
const getCollapsed = itemComponent => itemComponent.item.context.collapsed;
/**
 * @hidden
 */
const collapsed = itemComponent => getCollapsed(itemComponent) === true;
/**
 * @hidden
 */
const expanded = itemComponent => getCollapsed(itemComponent) === false;
const toggleFirst = collapsed => itemComponents => itemComponents.find(ic => getCollapsed(ic) === collapsed).item.context.collapsed = !collapsed;
/**
 * @hidden
 */
const collapseFirst = toggleFirst(false);
/**
 * @hidden
 */
const expandFirst = toggleFirst(true);

/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Represents the [Kendo UI Breadcrumb component for Angular]({% slug overview_breadcrumb %}).
 *
 * @example
 * ```ts-no-run
 * _@Component({
 *      selector: 'my-app',
 *      template: `
 *          <kendo-breadcrumb
 *              [items]="items"
 *              (itemClick)="onItemClick($event)">
 *          </kendo-breadcrumb>
 *      `
 * })
 * class AppComponent {
 *      public items: BreadCrumbItem[] = [
 *          { text: 'Home', title: 'Home', icon: 'home' },
 *          { text: 'Kids', title: 'Kids' },
 *          { text: '8y-16y', title: '8y-16y', disabled: true },
 *          { text: 'New collection', title: 'New collection' },
 *          { text: 'Jeans', title: 'Jeans' }
 *      ];
 *
 *      public onItemClick(item: BreadCrumbItem): void {
 *          console.log(item);
 *      }
 * }
 * ```
 */
class BreadCrumbComponent {
  constructor(localization, el, cdr, zone, renderer) {
    this.localization = localization;
    this.el = el;
    this.cdr = cdr;
    this.zone = zone;
    this.renderer = renderer;
    /**
     * Fires when a Breadcrumb item is clicked. The event will not be fired by disabled items and the last item.
     */
    this.itemClick = new EventEmitter();
    this.hostClasses = true;
    this.hostAriaLabel = 'Breadcrumb';
    this._items = [];
    this._collapseMode = 'auto';
    this._size = DEFAULT_SIZE;
    this.updateItems = new ReplaySubject();
    this.afterViewInit = new Subject();
    this.subscriptions = new Subscription();
    this.direction = 'ltr';
    validatePackage(packageMetadata);
    const updateItems$ = this.updateItems.asObservable().pipe(startWith([]));
    this.direction = localization.rtl ? 'rtl' : 'ltr';
    this.itemsData$ = updateItems$.pipe(map(items => items.filter(Boolean)), map(items => items.map((item, index, collection) => ({
      context: {
        collapsed: false,
        isLast: index === collection.length - 1,
        isFirst: index === 0
      },
      data: item
    }))), share());
    this.firstItem$ = updateItems$.pipe(map(items => {
      if (items.length > 0) {
        return [{
          context: {
            collapsed: false,
            isLast: items.length === 1,
            isFirst: true
          },
          data: items[0]
        }];
      }
      return [];
    }), share());
  }
  /**
   * The collection of items that will be rendered in the Breadcrumb.
   */
  set items(items) {
    this._items = items || [];
    this.updateItems.next(this._items);
  }
  get items() {
    return this._items;
  }
  /**
   * Specifies the collapse mode of the Breadcrumb ([see example]({% slug collapse_modes_breadcrumb %})).
   *
   * The possible values are:
   * - `auto` (default)&mdash;items are automatically collapsed based on the width of the Breadcrumb.
   * - `wrap`&mdash;items are wrapped on multiple rows.
   * - `none`&mdash;all items are expanded on the same row.
   *
   * For more information and example refer to the [Collapse Modes]({% slug collapse_modes_breadcrumb %}) article.
   */
  set collapseMode(mode) {
    if (isDevMode() && ['auto', 'wrap', 'none'].indexOf(mode) < 0) {
      throw new Error('Invalid collapse mode. Allowed values are "auto", "wrap" or "none". \nFor more details see https://www.telerik.com/kendo-angular-ui/components/navigation/api/BreadCrumbCollapseMode/');
    }
    this._collapseMode = mode || 'auto';
    this.updateItems.next(this.items);
  }
  get collapseMode() {
    return this._collapseMode;
  }
  /**
   * Specifies the padding of all Breadcrumb elements.
   *
   * The possible values are:
   * * `small`
   * * `medium` (default)
   * * `large`
   * * `none`
   */
  set size(size) {
    const newSize = size ? size : DEFAULT_SIZE;
    this.handleClasses(newSize, 'size');
    this._size = newSize;
  }
  get size() {
    return this._size;
  }
  get wrapMode() {
    return this.collapseMode === 'wrap';
  }
  get getDir() {
    return this.direction;
  }
  ngOnInit() {
    this.subscriptions.add(this.localization.changes.subscribe(({
      rtl
    }) => this.direction = rtl ? 'rtl' : 'ltr'));
    this.handleClasses(this.size, 'size');
  }
  ngAfterViewInit() {
    this.attachResizeHandler();
    this.afterViewInit.next();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  handleResize() {
    const autoCollapseCandidates = [...this.listComponent.renderedItems.toArray().filter(ri => !ri.item.context.isFirst && !ri.item.context.isLast)];
    const componentWidth = Math.floor(outerWidth(this.el.nativeElement));
    const itemsContainerWidth = Math.round(this.itemsContainers.toArray().map(el => outerWidth(el.nativeElement)).reduce((acc, curr) => acc + curr, 0));
    const nextExpandWidth = Math.ceil(([...autoCollapseCandidates].reverse().find(collapsed) || {
      width: 0
    }).width);
    // // shrink
    if (componentWidth <= itemsContainerWidth && autoCollapseCandidates.find(expanded)) {
      collapseFirst(autoCollapseCandidates);
      // needed by resize sensor
      this.cdr.detectChanges();
      return this.handleResize();
    }
    // expand
    if (componentWidth > itemsContainerWidth + nextExpandWidth && autoCollapseCandidates.find(collapsed)) {
      expandFirst([...autoCollapseCandidates].reverse());
      // needed by resize sensor
      this.cdr.detectChanges();
      return this.handleResize();
    }
  }
  shouldResize() {
    return isDocumentAvailable() && this.collapseMode === 'auto';
  }
  attachResizeHandler() {
    // resize when:
    // the component is initialized
    // the container is resized
    // items are added/removed
    this.subscriptions.add(merge(this.resizeSensor.resize, this.itemsData$, this.afterViewInit.asObservable()).pipe(filter(() => this.shouldResize())).subscribe(() => {
      this.zone.runOutsideAngular(() => setTimeout(() => {
        this.zone.run(() => {
          if (this.listComponent) {
            this.handleResize();
            this.resizeSensor.acceptSize();
          }
        });
      }));
    }));
  }
  handleClasses(value, input) {
    const elem = this.el.nativeElement;
    const classes = getStylingClasses(input, this[input], value);
    if (classes.toRemove) {
      this.renderer.removeClass(elem, classes.toRemove);
    }
    if (classes.toAdd) {
      this.renderer.addClass(elem, classes.toAdd);
    }
  }
}
BreadCrumbComponent.ɵfac = function BreadCrumbComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadCrumbComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2));
};
BreadCrumbComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BreadCrumbComponent,
  selectors: [["kendo-breadcrumb"]],
  contentQueries: function BreadCrumbComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, BreadCrumbItemTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemTemplate = _t.first);
    }
  },
  viewQuery: function BreadCrumbComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c13, 7);
      i0.ɵɵviewQuery(BreadCrumbListComponent, 7);
      i0.ɵɵviewQuery(_c14, 5, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.resizeSensor = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.listComponent = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.itemsContainers = _t);
    }
  },
  hostVars: 6,
  hostBindings: function BreadCrumbComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("aria-label", ctx.hostAriaLabel)("dir", ctx.getDir);
      i0.ɵɵclassProp("k-breadcrumb", ctx.hostClasses)("k-breadcrumb-wrap", ctx.wrapMode);
    }
  },
  inputs: {
    items: "items",
    separatorIcon: "separatorIcon",
    separatorSVGIcon: "separatorSVGIcon",
    collapseMode: "collapseMode",
    size: "size"
  },
  outputs: {
    itemClick: "itemClick"
  },
  exportAs: ["kendoBreadCrumb"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.breadcrumb'
  }]), i0.ɵɵStandaloneFeature],
  decls: 6,
  vars: 13,
  consts: [["itemsContainer", ""], ["resizeSensor", ""], ["kendoBreadCrumbList", "", "class", "k-breadcrumb-root-item-container", 3, "items", "itemTemplate", "collapseMode", "separatorIcon", "separatorSVGIcon", "itemClick", 4, "ngIf"], ["kendoBreadCrumbList", "", 1, "k-breadcrumb-container", 3, "itemClick", "items", "itemTemplate", "collapseMode", "separatorIcon", "separatorSVGIcon", "ngClass"], [3, "rateLimit"], ["kendoBreadCrumbList", "", 1, "k-breadcrumb-root-item-container", 3, "itemClick", "items", "itemTemplate", "collapseMode", "separatorIcon", "separatorSVGIcon"]],
  template: function BreadCrumbComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵtemplate(0, BreadCrumbComponent_ol_0_Template, 3, 7, "ol", 2);
      i0.ɵɵelementStart(1, "ol", 3, 0);
      i0.ɵɵpipe(3, "async");
      i0.ɵɵlistener("itemClick", function BreadCrumbComponent_Template_ol_itemClick_1_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.itemClick.emit($event));
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelement(4, "kendo-resize-sensor", 4, 1);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.collapseMode === "wrap");
      i0.ɵɵadvance();
      i0.ɵɵproperty("items", i0.ɵɵpipeBind1(3, 8, ctx.itemsData$))("itemTemplate", ctx.itemTemplate == null ? null : ctx.itemTemplate.templateRef)("collapseMode", ctx.collapseMode)("separatorIcon", ctx.separatorIcon)("separatorSVGIcon", ctx.separatorSVGIcon)("ngClass", i0.ɵɵpureFunction2(10, _c15, ctx.collapseMode === "wrap", ctx.collapseMode === "none"));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("rateLimit", 1000);
    }
  },
  dependencies: [NgIf, BreadCrumbListComponent, NgClass, ResizeSensorComponent, AsyncPipe],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadCrumbComponent, [{
    type: Component,
    args: [{
      exportAs: 'kendoBreadCrumb',
      selector: 'kendo-breadcrumb',
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.breadcrumb'
      }],
      template: `
        <ol
            #itemsContainer
            kendoBreadCrumbList
            class="k-breadcrumb-root-item-container"
            *ngIf="collapseMode === 'wrap'"
            [items]="firstItem$ | async"
            [itemTemplate]="itemTemplate?.templateRef"
            [collapseMode]="collapseMode"
            [separatorIcon]="separatorIcon"
            [separatorSVGIcon]="separatorSVGIcon"
            (itemClick)="itemClick.emit($event)"
        ></ol>
        <ol
            #itemsContainer
            kendoBreadCrumbList
            class="k-breadcrumb-container"
            [items]="itemsData$ | async"
            [itemTemplate]="itemTemplate?.templateRef"
            [collapseMode]="collapseMode"
            [separatorIcon]="separatorIcon"
            [separatorSVGIcon]="separatorSVGIcon"
            (itemClick)="itemClick.emit($event)"
            [ngClass]="{ '!k-flex-wrap': collapseMode === 'wrap', 'k-flex-none': collapseMode === 'none' }"
        ></ol>
        <kendo-resize-sensor [rateLimit]="1000" #resizeSensor></kendo-resize-sensor>
    `,
      standalone: true,
      imports: [NgIf, BreadCrumbListComponent, NgClass, ResizeSensorComponent, AsyncPipe]
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Renderer2
    }];
  }, {
    items: [{
      type: Input
    }],
    separatorIcon: [{
      type: Input
    }],
    separatorSVGIcon: [{
      type: Input
    }],
    collapseMode: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    itemClick: [{
      type: Output
    }],
    resizeSensor: [{
      type: ViewChild,
      args: ['resizeSensor', {
        static: true
      }]
    }],
    itemsContainers: [{
      type: ViewChildren,
      args: ['itemsContainer', {
        read: ElementRef
      }]
    }],
    listComponent: [{
      type: ViewChild,
      args: [BreadCrumbListComponent, {
        static: true
      }]
    }],
    itemTemplate: [{
      type: ContentChild,
      args: [BreadCrumbItemTemplateDirective]
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class.k-breadcrumb']
    }],
    wrapMode: [{
      type: HostBinding,
      args: ['class.k-breadcrumb-wrap']
    }],
    hostAriaLabel: [{
      type: HostBinding,
      args: ['attr.aria-label']
    }],
    getDir: [{
      type: HostBinding,
      args: ['attr.dir']
    }]
  });
})();

/**
* Utility array that contains all `ActionSheet` related components and directives.
*/
const KENDO_ACTIONSHEET = [ActionSheetComponent, ActionSheetHeaderTemplateDirective, ActionSheetItemTemplateDirective, ActionSheetContentTemplateDirective, ActionSheetFooterTemplateDirective, ActionSheetTemplateDirective];
/**
* Utility array that contains all `AppBar` related components and directives.
*/
const KENDO_APPBAR = [AppBarComponent, AppBarSectionComponent, AppBarSpacerComponent];
/**
* Utility array that contains all `BottomNavigation` related components and directives.
*/
const KENDO_BOTTOMNAVIGATION = [BottomNavigationComponent, BottomNavigationItemTemplateDirective];
/**
* Utility array that contains all `BreadCrumb` related components and directives.
*/
const KENDO_BREADCRUMB = [BreadCrumbComponent, BreadCrumbItemTemplateDirective];
/**
* Utility array that contains all `@progress/kendo-angular-navigation` related components and directives.
*/
const KENDO_NAVIGATION = [...KENDO_ACTIONSHEET, ...KENDO_APPBAR, ...KENDO_BOTTOMNAVIGATION, ...KENDO_BREADCRUMB];

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the AppBar component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the AppBar module
 * import { AppBarModule } from '@progress/kendo-angular-navigation';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { BrowserModule } from '@angular/platform-browser';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, AppBarModule], // import AppBar module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class AppBarModule {}
AppBarModule.ɵfac = function AppBarModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AppBarModule)();
};
AppBarModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AppBarModule
});
AppBarModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [KENDO_APPBAR]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppBarModule, [{
    type: NgModule,
    args: [{
      exports: [...KENDO_APPBAR],
      imports: [...KENDO_APPBAR]
    }]
  }], null, null);
})();

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Navigation components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Navigation module
 * import { NavigationModule } from '@progress/kendo-angular-navigation';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, NavigationModule], // import Navigation module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class NavigationModule {}
NavigationModule.ɵfac = function NavigationModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NavigationModule)();
};
NavigationModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NavigationModule
});
NavigationModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [IconsService, ResizeBatchService],
  imports: [ActionSheetComponent, AppBarComponent, AppBarSectionComponent, AppBarSpacerComponent, BottomNavigationComponent, BreadCrumbComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NavigationModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_NAVIGATION],
      exports: [...KENDO_NAVIGATION],
      providers: [IconsService, ResizeBatchService]
    }]
  }], null, null);
})();

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the BreadCrumb component.
 * @example
 *
 * ```ts-no-run
 * // Import the BreadCrumb module
 * import { BreadCrumbModule } from '@progress/kendo-angular-navigation';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { BrowserModule } from '@angular/platform-browser';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, BreadCrumbModule], // import BreadCrumb module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class BreadCrumbModule {}
BreadCrumbModule.ɵfac = function BreadCrumbModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BreadCrumbModule)();
};
BreadCrumbModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BreadCrumbModule
});
BreadCrumbModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [IconsService, ResizeBatchService],
  imports: [BreadCrumbComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BreadCrumbModule, [{
    type: NgModule,
    args: [{
      exports: [...KENDO_BREADCRUMB],
      imports: [...KENDO_BREADCRUMB],
      providers: [IconsService, ResizeBatchService]
    }]
  }], null, null);
})();

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the BottomNavigation component.
 * @example
 *
 * ```ts-no-run
 * // Import the BottomNavigation module
 * import { BottomNavigationModule } from '@progress/kendo-angular-navigation';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { BrowserModule } from '@angular/platform-browser';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, BottomNavigationModule], // import BottomNavigation module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class BottomNavigationModule {}
BottomNavigationModule.ɵfac = function BottomNavigationModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BottomNavigationModule)();
};
BottomNavigationModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BottomNavigationModule
});
BottomNavigationModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [IconsService],
  imports: [BottomNavigationComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BottomNavigationModule, [{
    type: NgModule,
    args: [{
      exports: [...KENDO_BOTTOMNAVIGATION],
      imports: [...KENDO_BOTTOMNAVIGATION],
      providers: [IconsService]
    }]
  }], null, null);
})();

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the ActionSheet component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the ActionSheet module
 * import { ActionSheetModule } from '@progress/kendo-angular-navigation';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { BrowserModule } from '@angular/platform-browser';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, ActionSheetModule], // import ActionSheet module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class ActionSheetModule {}
ActionSheetModule.ɵfac = function ActionSheetModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ActionSheetModule)();
};
ActionSheetModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ActionSheetModule
});
ActionSheetModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [IconsService],
  imports: [ActionSheetComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionSheetModule, [{
    type: NgModule,
    args: [{
      exports: [...KENDO_ACTIONSHEET],
      imports: [...KENDO_ACTIONSHEET],
      providers: [IconsService]
    }]
  }], null, null);
})();

// AppBar exports

/**
 * Generated bundle index. Do not edit.
 */

export { ActionSheetComponent, ActionSheetContentTemplateDirective, ActionSheetFooterTemplateDirective, ActionSheetHeaderTemplateDirective, ActionSheetItemClickEvent, ActionSheetItemTemplateDirective, ActionSheetModule, ActionSheetTemplateDirective, AppBarComponent, AppBarModule, AppBarSectionComponent, AppBarSpacerComponent, BottomNavigationComponent, BottomNavigationItemTemplateDirective, BottomNavigationModule, BottomNavigationSelectEvent, BreadCrumbComponent, BreadCrumbItemComponent, BreadCrumbItemTemplateDirective, BreadCrumbListComponent, BreadCrumbModule, KENDO_ACTIONSHEET, KENDO_APPBAR, KENDO_BOTTOMNAVIGATION, KENDO_BREADCRUMB, KENDO_NAVIGATION, NavigationModule };