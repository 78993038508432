import { Pipe, PipeTransform } from '@angular/core';
import { InputType } from '../enums/InputType';

@Pipe({
  name: 'numberPipe',
  standalone: true,
})
export class NumberPipe implements PipeTransform {
  transform(value: number | string, type: InputType = InputType.number, locale = 'nl-BE'): string {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    }).format(Number(value));
  }

  transformInput(value: number | string, locale = 'nl-BE'): string {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    }).format(Number(value));
  }

  transformToInternational(value: number | string, type: InputType, locale = 'en-US'): string {
    const max = type === InputType.number ? 0 : 3;
    const min = type === InputType.number ? 0 : 2;
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    }).format(Number(value));
  }

  transformInteger(value: number | string, locale = 'nl-BE'): string {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Number(value));
  }
}
